import React from "react";
import PropTypes from "prop-types";

const FacebookIcon = ({ width = 10, height = 20, ...attrs }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    {...attrs}
  >
    <path
      fill="#227AEF"
      d="M6.084 6.553V4.83c0-.259.011-.46.035-.6a1.18 1.18 0 01.16-.42.657.657 0 01.406-.285 2.83 2.83 0 01.744-.079h1.69V0H6.417C4.853 0 3.73.378 3.047 1.135c-.682.756-1.023 1.87-1.023 3.343v2.075H0V10h2.024v10h4.06V10h2.702l.357-3.447h-3.06z"
    />
  </svg>
);

FacebookIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default FacebookIcon;
