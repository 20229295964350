import * as React from "react";

function Copy(props) {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.764 4.236a4.219 4.219 0 00-5.966 0l-3.526 3.526c-1.704 1.704-1.546 4.42 0 5.966.259.259.545.47.848.643l.644-.643c.422-.422.273-.916.265-1.272a2.048 2.048 0 01-.265-.22c-.794-.793-.83-2.154 0-2.983l3.525-3.526a2.111 2.111 0 012.984 0c.822.823.822 2.16 0 2.983l-2.331 2.331c.067.373.472 1.256.264 2.713.01-.01.022-.016.032-.026l3.526-3.526a4.219 4.219 0 000-5.966z"
        fill="#24272E"
      />
      <path
        d="M14.001 9.999a4.015 4.015 0 00-.848-.644l-.644.644c-.422.422-.273.915-.266 1.272.093.065.183.136.266.22.794.793.83 2.153 0 2.983L8.71 18.273a2.111 2.111 0 01-2.983 0 2.112 2.112 0 010-2.983l2.604-2.604c-.067-.373-.472-1.256-.264-2.714-.01.01-.022.017-.032.027l-3.8 3.8a4.219 4.219 0 005.967 5.965L14 15.965c1.673-1.672 1.581-4.385 0-5.966z"
        fill="#24272E"
      />
    </svg>
  );
}

export default Copy;
