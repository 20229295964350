import React from "react";
import PropTypes from "prop-types";
import trackable from "../../../../analytics/trackables/trackable";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import { placements } from "../../../../components/Popover/Popover";
import TenantServiceFeeInfoPopOver from "../../../../components/TenantServiceFeeInfoPopOver/TenantServiceFeeInfoPopOver";
import { formatCurrency } from "../../../../utils/prices";

CostOverview.propTypes = {
  t: PropTypes.func.isRequired,
  listing: PropTypes.object.isRequired,
  lang: PropTypes.string,
  tracker: PropTypes.object.isRequired,
};

function CostOverview({ t, lang, listing, tracker }) {
  const charges = {
    furnitureSurcharge: t("listings.costOverview.furnitureSurcharge"),
    operatingCosts: t("listings.costOverview.operatingCosts"),
    heating: t("listings.costOverview.heating"),
    electricity: t("listings.costOverview.electricity"),
    internet: t("listings.costOverview.internet"),
  };

  const chargeList = () => {
    const chargeList = Object.values(charges);
    return (
      <div className="CostOverview-chargeList">
        {
          // temporarily hardcoded, should become dynamic later
          chargeList.map((e, i) => (
            <p className="CostOverview-chargeTag" key={i}>
              {e}
            </p>
          ))
        }
      </div>
    );
  };

  return (
    <div className="CostOverview">
      <div className="CostOverview-itemRow">
        <h4>{t("listings.costOverview.monthlyRent")}</h4>
        <p>
          {formatCurrency({
            cents: listing.price,
            currency: listing.currency,
            lang,
          })}
        </p>
      </div>
      <div className="CostOverview-tags">
        <h4>{t("listings.costOverview.chargesIncluded")}</h4>
        {chargeList()}
      </div>
      <div className="CostOverview-itemRow">
        <h4>{t("listings.costOverview.deposit")}</h4>
        <p>
          {formatCurrency({
            cents: listing.deposit,
            currency: listing.currency,
            lang,
          })}
        </p>
      </div>
      <div className="CostOverview-itemRow">
        <h4>{t("listings.costOverview.cleaningFee")}</h4>
        <p>
          {formatCurrency({
            cents: listing.finalCleaningFee,
            currency: listing.currency,
            lang,
          })}
        </p>
      </div>
      {trackable(
        <div
          className="CostOverview-itemRow"
          data-testid="CostOverview-TenantServiceFee"
        >
          <TenantServiceFeeInfoPopOver
            placement={placements.right}
            title={
              <h4 className="CostOverview-TenantServiceFee">
                <span>{t("wunderflatsTenantServiceFee")}</span>
                <InfoIcon />
              </h4>
            }
          />
          <p>
            {formatCurrency({
              cents: listing.sftValue,
              currency: listing.currency,
              lang,
            })}{" "}
            {t("taxInclusive")}
          </p>
        </div>,
        () => {
          tracker.events.trackTenantServiceFeePriceListingDetailPageViewed({
            listingId: listing._id,
            sftValue: listing.sftValue,
            currency: "EUR",
          });
        },
      )}
    </div>
  );
}

export default CostOverview;
