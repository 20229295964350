import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../components/DesignSystem/Button/Button";
import StatusCard from "../../../../components/StatusCard/StatusCard";
import {
  dismissDurationMessage,
  isViennaListing,
  shouldDisplayDurationMessage,
} from "../../../../utils/vienna";

const propTypes = {
  t: PropTypes.func,
  country: PropTypes.string,
  zipCode: PropTypes.string,
  maxBookingDuration: PropTypes.number,
  cookies: PropTypes.object,
};

const ViennaStayDurationMessage = ({
  t,
  country,
  zipCode,
  maxBookingDuration,
  cookies,
}) => {
  const [shouldDisplay, setShouldDisplay] = useState(
    shouldDisplayDurationMessage({
      cookies,
    }),
  );

  const onClickHandler = () => {
    dismissDurationMessage();
    setShouldDisplay(false);
  };
  if (!isViennaListing({ country, zipCode }) || !shouldDisplay) {
    return null;
  }

  return (
    <div
      className="ViennaStayDurationMessage"
      data-testid="ViennaStayDurationMessage"
    >
      <StatusCard intent="brand">
        <div className="ViennaStayDurationMessage-content">
          <p className="ViennaStayDurationMessage-title">
            {t("viennaStayDurationTitle", { maxBookingDuration })}
          </p>
          <p>{t("viennaStayDurationContent", { maxBookingDuration })}</p>
          <p>{t("viennaStayDurationContentPt2")}</p>
        </div>
        <Button
          className="ViennaStayDurationMessage-dismissButton"
          onClick={onClickHandler}
          dataTestId="ViennaStayDurationMessage-dismiss"
          colorVariant="secondary"
          size="medium"
        >
          {t("dismiss")}
        </Button>
      </StatusCard>
    </div>
  );
};

ViennaStayDurationMessage.propTypes = propTypes;
export default ViennaStayDurationMessage;
