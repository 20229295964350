import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import store from "store2";
import { INTERNAL_REFERRER_SESSION_STORAGE_KEY } from "../../analytics/constants";
import { useAuth } from "../../contexts/AuthContext";
import { useTracker } from "../../spages/spa/context/TrackerContext";
import ListingsList from "../ListingsList/ListingsList";
import "./SimilarListings.scss";

const SimilarListings = ({
  url,
  title,
  query,
  city,
  listingId,
  similarToGroupId,
  api,
  trackComponentLocation,
}) => {
  const [listings, setListings] = useState([]);
  const { tracker } = useTracker();
  const { user } = useAuth();

  const isBookingWizardPage = trackComponentLocation === "BookingWizardPage";
  const isListingsDetailsPage =
    trackComponentLocation === "ListingsDetailsPage";

  useEffect(() => {
    const { from } = query;
    let fromDate = dayjs().utc().format("YYYY-MM-DD");
    if (dayjs().isValid(from) && dayjs(from).isAfter(dayjs().utc())) {
      fromDate = dayjs().utc(from).format("YYYY-MM-DD");
    }

    api.listings
      .getSimilarListings(listingId, {
        from: fromDate,
        to: dayjs.utc(fromDate).add(1, "months").format("YYYY-MM-DD"),
      })
      .then((similarListings) => {
        setListings(similarListings || []);
      })
      .catch(() => setListings([]));
  }, [query, api, listingId]);

  const onClickListing = (listing, index) => {
    store.set(INTERNAL_REFERRER_SESSION_STORAGE_KEY, {
      r: "SIMILAR",
    });
    api.listings
      .createSimilarListingClickedEvent({
        currentGroupId: similarToGroupId,
        clickedGroupId: listing.groupId,
      })
      .catch((error) => {
        window.rollbar?.error(error);
      });

    if (isBookingWizardPage) {
      tracker.events.trackSimilarListingClickedOnBookingWizard({
        userId: user?._id || null,
        clickedGroupId: listing.groupId,
        currentGroupId: similarToGroupId,
        position: index + 1,
      });
    }

    if (isListingsDetailsPage) {
      tracker.events.trackSimilarListingClickedOnLDP({
        userId: user?._id || null,
        clickedGroupId: listing.groupId,
        currentGroupId: similarToGroupId,
        position: index + 1,
      });
    }
  };

  return listings.length ? (
    <section className="SimilarListings" data-testid="SimilarListings">
      <h3 className="SimilarListings-title">{title}</h3>
      <ListingsList
        showtitle={false}
        url={url}
        city={city}
        items={listings}
        query={query}
        onClickListing={onClickListing}
        listingCardTrackingCallback={(l) => {
          api.analytics.createListingViewedEvent(
            l.groupId,
            "SIMILAR_LISTINGS_TO_APARTMENT",
            similarToGroupId,
          );
        }}
      />
    </section>
  ) : null;
};

SimilarListings.propTypes = {
  url: PropTypes.func.isRequired,
  city: PropTypes.string,
  title: PropTypes.string.isRequired,
  listingId: PropTypes.string.isRequired,
  query: PropTypes.object.isRequired,
  similarToGroupId: PropTypes.string.isRequired,
  api: PropTypes.shape({
    analytics: PropTypes.object.isRequired,
    listings: PropTypes.object.isRequired,
  }).isRequired,
  trackComponentLocation: PropTypes.oneOf([
    "BookingWizardPage",
    "ListingsDetailsPage",
  ]),
};

export default SimilarListings;
