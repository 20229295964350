import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

ServicesList.propTypes = {
  t: PropTypes.func.isRequired,
  services: PropTypes.object.isRequired,
  labels: PropTypes.array.isRequired,
};

function ServicesList({ t, services, labels }) {
  const broadcastingFeeIncluded = t("broadcastingFee.true");
  const broadcastingFeeNotIncluded = t("broadcastingFee.false");

  return (
    <React.Fragment>
      <div>
        {(labels?.includes("GERMANY") || labels?.includes("AUSTRIA")) && (
          <span
            className={classnames(
              "ServicesList-serviceItemOuter",
              services.cityRegistrationDocuments ? "check" : "unavailable",
            )}
          >
            {t("cityRegistrationDocuments", {
              certificateLabel: labels?.includes("GERMANY")
                ? "Wohnungsgeberbestätigung"
                : "Meldezettel",
            })}
          </span>
        )}
        <span
          className={classnames(
            "ServicesList-serviceItemOuter",
            services.tenantCanChangeThePostBoxName ? "check" : "unavailable",
          )}
        >
          {t("tenantCanChangeThePostBoxName")}
        </span>
        <span
          className={classnames(
            "ServicesList-serviceItemOuter",
            services.broadcastingFeeIncludedInRent ? "check" : "unavailable",
          )}
        >
          <div>
            <span>{t("listings.services.broadcastingFee")}</span>
            <div className="ServicesList-serviceDetail">
              {services.broadcastingFeeIncludedInRent
                ? broadcastingFeeIncluded
                : broadcastingFeeNotIncluded}
            </div>
          </div>
        </span>
      </div>
      <div className="ServicesList-wrapper">
        <div
          className={classnames(
            "ServicesList-serviceItemOuter",
            services.cleaningV2 !== "NOT_AVAILABLE" ? "check" : "unavailable",
          )}
        >
          <div>
            <span>{t("listings.services.cleaning")}</span>
            <div className="ServicesList-serviceDetail">
              {t(`cleaning.${services.cleaningV2}`)}
            </div>
          </div>
        </div>
        <div
          className={classnames(
            "ServicesList-serviceItemOuter",
            services.parkingV2 !== "NOT_AVAILABLE" ? "check" : "unavailable",
          )}
        >
          <div>
            <span>{t("listings.services.parking")}</span>
            <div className="ServicesList-serviceDetail">
              {t(`parking.${services.parkingV2}`)}
            </div>
          </div>
        </div>
        <div
          className={classnames(
            "ServicesList-serviceItemOuter",
            services.conciergeV2 !== "NOT_AVAILABLE" ? "check" : "unavailable",
          )}
        >
          <div>
            <span>{t("listings.services.concierge")}</span>
            <div className="ServicesList-serviceDetail">
              {t(`concierge.${services.conciergeV2}`)}
            </div>
          </div>
        </div>
        <div
          className={classnames(
            "ServicesList-serviceItemOuter",
            services.fitnessV2 !== "NOT_AVAILABLE" ? "check" : "unavailable",
          )}
        >
          <div>
            <span>{t("listings.services.fitness")}</span>
            <div className="ServicesList-serviceDetail">
              {t(`fitness.${services.fitnessV2}`)}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ServicesList;
