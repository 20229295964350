import dataLayer from "../../../../utils/data-layer";
import { toEuro } from "../../../../utils/prices";

exports.getDataLayerVariables = (
  pageType,
  { user, totalRent, query = {}, listing, adults, children = 0, ...more } = {},
) => {
  const baseDataLayer = dataLayer.initialDataLayer(pageType, {
    user,
  });

  const extendedDataLayer = {
    ...baseDataLayer[0],

    listingGroupId: listing.groupId,
    city: listing.address.city,
    monthlyRent: toEuro(listing.price),
    rooms: listing.roomsInformation?.length || "",
    listingType: listing.partOfGroup ? "multi" : "single",
    neighborhood: listing.address.districts
      ? listing.address.districts[0] || ""
      : "",
    ...query,
  };

  if (query.from && query.to) {
    extendedDataLayer.totalRent = toEuro(totalRent);
  }

  if (adults != null && children != null) {
    extendedDataLayer.tenants = adults + children;
  }

  Object.assign(extendedDataLayer, more);

  return extendedDataLayer;
};
