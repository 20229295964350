import React from "react";
import PropTypes from "prop-types";

const InfoIcon = ({
  width = 18,
  height = 18,
  fillColor = "#24272E",
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      {...props}
    >
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M9 1.75C4.99594 1.75 1.75 4.99594 1.75 9C1.75 13.0041 4.99594 16.25 9 16.25C13.0041 16.25 16.25 13.0041 16.25 9C16.25 4.99594 13.0041 1.75 9 1.75ZM0.25 9C0.25 4.16751 4.16751 0.25 9 0.25C13.8325 0.25 17.75 4.16751 17.75 9C17.75 13.8325 13.8325 17.75 9 17.75C4.16751 17.75 0.25 13.8325 0.25 9Z"
        clipRule="evenodd"
      />
      <path
        fill={fillColor}
        d="M8.05078 10.7922C8.05078 10.2727 8.11523 9.85864 8.24414 9.55005C8.37305 9.24146 8.62695 8.90356 9.00586 8.53638C9.38867 8.16528 9.63086 7.90161 9.73242 7.74536C9.88867 7.50708 9.9668 7.24927 9.9668 6.97192C9.9668 6.60474 9.875 6.32544 9.69141 6.13403C9.51172 5.93872 9.24609 5.84106 8.89453 5.84106C8.55859 5.84106 8.28711 5.93677 8.08008 6.12817C7.87695 6.31567 7.77539 6.57153 7.77539 6.89575H6.35156C6.35938 6.20435 6.59375 5.65747 7.05469 5.25513C7.51953 4.85278 8.13281 4.65161 8.89453 4.65161C9.67969 4.65161 10.291 4.85083 10.7285 5.24927C11.1699 5.64771 11.3906 6.20435 11.3906 6.91919C11.3906 7.55591 11.0938 8.18286 10.5 8.80005L9.7793 9.50903C9.52148 9.802 9.38867 10.2297 9.38086 10.7922H8.05078ZM7.95117 12.6145C7.95117 12.384 8.02344 12.1985 8.16797 12.0579C8.3125 11.9133 8.50781 11.8411 8.75391 11.8411C9.00391 11.8411 9.20117 11.9153 9.3457 12.0637C9.49023 12.2083 9.5625 12.3918 9.5625 12.6145C9.5625 12.8293 9.49219 13.009 9.35156 13.1536C9.21094 13.2981 9.01172 13.3704 8.75391 13.3704C8.49609 13.3704 8.29688 13.2981 8.15625 13.1536C8.01953 13.009 7.95117 12.8293 7.95117 12.6145Z"
      />
    </svg>
  );
};

InfoIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  fillColor: PropTypes.string,
};

export default InfoIcon;
