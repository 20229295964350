import React from "react";
import PropTypes from "prop-types";

const LinkedInIcon = ({ width = 30, height = 30, ...attrs }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    width={width}
    height={height}
    {...attrs}
  >
    <path
      fill="#0E76A8"
      d="M24 15.545V22.2h-3.859v-6.21c0-1.56-.557-2.624-1.954-2.624-1.066 0-1.7.717-1.98 1.41-.101.249-.127.593-.127.941V22.2h-3.86s.052-10.517 0-11.606h3.86v1.645l-.026.038h.026v-.038c.513-.789 1.427-1.917 3.478-1.917 2.539 0 4.442 1.659 4.442 5.223zM8.184 5C6.864 5 6 5.867 6 7.005c0 1.114.839 2.006 2.133 2.006h.025c1.346 0 2.183-.892 2.183-2.006C10.315 5.867 9.504 5 8.184 5zM6.229 22.2h3.858V10.595H6.229v11.607z"
    />
  </svg>
);

LinkedInIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
};

export default LinkedInIcon;
