import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../components/DesignSystem/Button/Button";
import { IconButton } from "../../../../components/DesignSystem/IconButton/IconButton";
import CloseIcon from "../../../../components/Icons/Close";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import VerifiedIcon from "../../../../components/Icons/VerifiedIcon";
import Popover, { placements } from "../../../../components/Popover/Popover";

const VerifiedBadge = ({ t, smallScreen = false }) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  // Since LDP has a sticky footer on mobile, we need to increase
  // z-index of the popover in order to prevent overlapping.
  useEffect(() => {
    if (!smallScreen) {
      return;
    }

    const drawer = document.getElementsByClassName("Drawer");

    if (drawer && drawer[0]) {
      drawer[0].style.zIndex = isPopoverOpen ? 1100 : 10;
    }
  }, [isPopoverOpen, smallScreen]);

  const togglePopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  return (
    <Popover
      className="VerifiedBadgePopover"
      showInDrawer={smallScreen}
      show={isPopoverOpen}
      onClose={closePopover}
      placement={placements.left}
      preventScroll
      around={
        <button
          type="button"
          className="ListingDetailsPage-VerifiedBadgeButton"
          data-testid="ListingDetailsPage-VerifiedBadgeButton"
          onClick={togglePopover}
        >
          <VerifiedIcon />
          <span className="ListingsDetailsPage-VerifiedBadgeButton-text">
            {t("spages.listing.ListingDetailsPageBase.verifiedListing")}
          </span>
          <InfoIcon />
        </button>
      }
    >
      {!smallScreen && (
        <IconButton
          className="VerifiedBadgePopover-closeButton"
          onClick={closePopover}
          icon={<CloseIcon width={18} height={18} />}
        />
      )}

      <div
        data-testid="VerifiedBadgePopover-content"
        className="VerifiedBadgePopover-content"
      >
        <h3 className="VerifiedBadgePopover-contentTitle">
          {t(
            "spages.listing.ListingDetailsPageBase.verifiedBadgePopover.title",
          )}
        </h3>

        <p className="VerifiedBadgePopover-contentInfo">
          {t("spages.listing.ListingDetailsPageBase.verifiedBadgePopover.info")}
        </p>

        <hr className="VerifiedBadgePopover-separator" />

        <p className="VerifiedBadgePopover-contact">
          {t(
            "spages.listing.ListingDetailsPageBase.verifiedBadgePopover.contact",
          )}
          <a
            className="VerifiedBadgePopover-contact-email"
            href="mailto:support@wunderflats.com"
          >
            support@wunderflats.com
          </a>
        </p>

        <Button
          dataTestId="ListingDetailsPage-VerifiedBadgeGotItButton"
          onClick={closePopover}
          colorVariant="secondary"
          widthVariant="fullWidth"
          className="ListingDetailsPage-VerifiedBadgeGotItButton"
        >
          {t(
            "spages.listing.ListingDetailsPageBase.verifiedBadgePopover.gotItButton",
          )}
        </Button>
      </div>
    </Popover>
  );
};

VerifiedBadge.propTypes = {
  t: PropTypes.func.isRequired,
  smallScreen: PropTypes.bool,
};

export default VerifiedBadge;
