import React, { useRef } from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import serialize from "form-serialize";
import DateRangePickerTrigger from "../../../../../components/DateRangePicker/DateRangePickerTrigger/DateRangePickerTrigger";
import { Button } from "../../../../../components/DesignSystem/Button/Button";
import FormHelpText from "../../../../../components/FormHelpText/FormHelpText";
import { placements } from "../../../../../components/Popover/Popover";
import { isDateBlocked } from "../../../../../utils/fromToDatesValidation";
import { useI18n } from "../../../context/I18nContext";
import ListingPriceText from "./ListingPriceText";

const dateInput = PropTypes.shape({
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
});

const propTypes = {
  minBookingDuration: PropTypes.number.isRequired,
  maxBookingDuration: PropTypes.number,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  available: PropTypes.bool.isRequired,
  availableFrom: PropTypes.string,
  availableTo: PropTypes.string,
  from: dateInput,
  to: dateInput,
  error: PropTypes.string,
  blockedDurations: PropTypes.array,
  submitButtonText: PropTypes.string,
  onReset: PropTypes.func,
  canListingBeRequested: PropTypes.shape({
    status: PropTypes.bool,
    reason: PropTypes.oneOf(["LISTING_ALREADY_REQUESTED"]),
    requesting: PropTypes.bool.isRequired,
  }).isRequired,
  ownListing: PropTypes.bool,
  price: PropTypes.number,
  currency: PropTypes.string,
  isSmallScreen: PropTypes.bool,
  onDatePickerVisible: PropTypes.func,
};

const StartCreateBookingWizardForm = ({
  minBookingDuration,
  maxBookingDuration,
  onSubmit,
  loading,
  available,
  availableFrom,
  availableTo,
  from,
  to,
  error,
  blockedDurations = [],
  submitButtonText,
  onReset,
  canListingBeRequested,
  ownListing,
  price,
  currency,
  isSmallScreen,
  onDatePickerVisible,
}) => {
  const { t, lang } = useI18n();
  const bookingButtonRef = useRef(null);

  const today = dayjs().startOf("day");
  const minDate = dayjs(availableFrom).isBefore(today)
    ? today
    : dayjs(availableFrom);
  const availableToEndOfDay = availableTo
    ? dayjs(availableTo).endOf("day").subtract(1, "day")
    : null;

  let status = "unavailable";
  if (loading) status = "loading";
  if (available) status = "available";

  const isAvailableToDateInPast =
    availableTo && availableToEndOfDay.isBefore(today);
  const areToAndFromDatesSelected = from.value && to.value;
  const isSubmitButtonDisabled =
    ownListing ||
    isAvailableToDateInPast ||
    loading ||
    !available ||
    canListingBeRequested.status === false ||
    !areToAndFromDatesSelected;

  const renderError = () => {
    const areToAndFromDatesSelected = from.value && to.value;

    let message = (
      <FormHelpText dataTestId="FormHelpText-incomplete">
        {t(
          "spages.listing.ListingDetailsPageBase.StartCreateBookingWizardForm.errors.incomplete",
        )}
      </FormHelpText>
    );

    if (ownListing) {
      message = (
        <FormHelpText dataTestId="FormHelpText-youCannotRequestYourOwnListing">
          {t("youCannotRequestYourOwnListing")}
        </FormHelpText>
      );
    } else if (
      canListingBeRequested.status === false &&
      canListingBeRequested.reason === "LISTING_ALREADY_REQUESTED"
    ) {
      message = (
        <div className="StartCreateBookingWizardForm-previouslyRequestedMessage">
          {t("listingAlreadyRequested")}
        </div>
      );
    } else if (!available) {
      message = (
        <FormHelpText dataTestId="FormHelpText-youWontBeChargedYet">
          {t(
            "spages.multilisting.MultilistingDetailsPage.MultilistingUnavailableCard.text",
          )}
        </FormHelpText>
      );
    } else if (areToAndFromDatesSelected) {
      message = (
        <FormHelpText dataTestId="FormHelpText-youWontBeChargedYet">
          {t("youWontBeChargedYet")}
        </FormHelpText>
      );
    }

    return (
      <React.Fragment>
        {error ? (
          <div className="StartCreateBookingWizardForm-error">
            {t(
              `spages.listing.ListingDetailsPageBase.StartCreateBookingWizardForm.errors.${error}`,
            )}
          </div>
        ) : (
          message
        )}
      </React.Fragment>
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const values = serialize(e.target, { hash: true, empty: true });
    onSubmit(values);
  };

  return (
    <form className="StartCreateBookingWizardForm" onSubmit={handleSubmit}>
      <ListingPriceText t={t} price={price} currency={currency} lang={lang} />

      <input name="from" type="hidden" value={from.value} />
      <input name="to" type="hidden" value={to.value} />

      <div className="StartCreateBookingWizardFormDateRangePicker">
        <div className="StartCreateBookingWizardFormDateRangePicker-label">
          {t("components.DateRangePicker.stayDuration")}
        </div>
        <DateRangePickerTrigger
          fromDate={from.value}
          toDate={to.value}
          onChange={({ fromDate, toDate }) => {
            from.onChange(fromDate);
            to.onChange(toDate);
          }}
          showFlexibleDates={false}
          minBookingDuration={minBookingDuration || 1}
          maxBookingDuration={maxBookingDuration}
          minDate={minDate}
          maxDate={availableToEndOfDay}
          blockedDurations={blockedDurations}
          isDayBlocked={(date) =>
            isDateBlocked({
              date,
              blockedDurations,
              available,
            })
          }
          showApplyButton
          applyButtonType="button"
          onApplyButtonClick={() => bookingButtonRef?.current?.focus()}
          isMobile={isSmallScreen}
          onDatePickerVisible={onDatePickerVisible}
          popoverPlacement={placements.bottomEnd}
          dateRangeSeparator="-"
          triggerClassName="StartCreateBookingWizardFormDateRangePicker-trigger"
          previewButtonClassName="StartCreateBookingWizardFormDateRangePicker-previewDateButton"
          previewButtonActiveClassName="StartCreateBookingWizardFormDateRangePicker-previewDateButton--active"
          previewButtonInactiveClassName="StartCreateBookingWizardFormDateRangePicker-previewDateButton--inactive"
          previewButtonSelectedClassName="StartCreateBookingWizardFormDateRangePicker-previewDateButton--selected"
          previewButtonAppliedClassName="StartCreateBookingWizardFormDateRangePicker-previewDateButton--applied"
          previewButtonClearedClassName="StartCreateBookingWizardFormDateRangePicker-previewDateButton--cleared"
          dateSeparatorClassName="StartCreateBookingWizardFormDateRangePicker-dateSeparator"
          dateSeparatorInactiveClassName="StartCreateBookingWizardFormDateRangePicker-dateSeparator--inactive"
          dateCtaPanelClassName="StartCreateBookingWizardFormDateRangePicker-ctaPanel"
        />
      </div>

      <Button
        dataTestId="StartCreateBookingWizardForm-SubmitButton"
        className="StartCreateBookingWizardForm-SubmitButton"
        widthVariant="fullWidth"
        colorVariant="primary"
        type="submit"
        loading={loading}
        spinnerProps={{ showOverlay: false }}
        disabled={isSubmitButtonDisabled}
        size="medium"
        ref={bookingButtonRef}
      >
        {submitButtonText ||
          t(
            `spages.listing.ListingDetailsPageBase.StartCreateBookingWizardForm.status.${status}`,
          )}
      </Button>
      {onReset && !available && (
        <button
          className="btn btn-secondary StartCreateBookingWizardForm-ChangeDateButton"
          onClick={onReset}
        >
          {t("spages.multilisting.MultilistingDetailsPage.form.changeDates")}
        </button>
      )}
      {renderError()}
    </form>
  );
};

StartCreateBookingWizardForm.propTypes = propTypes;
export default StartCreateBookingWizardForm;
