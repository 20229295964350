import React from "react";
import Copy from "../Icons/Copy";
import Envelope from "../Icons/Envelope";
import Facebook from "../Icons/Facebook";
import Instagram from "../Icons/Instagram";
import LinkedInIcon from "../Icons/LinkedIn";
import Messenger from "../Icons/Messenger";
import Telegram from "../Icons/Telegram";
import Twitter from "../Icons/Twitter";
import WhatsApp from "../Icons/WhatsApp";

export const ICONS = {
  Facebook: <Facebook alt="Facebook" className="SharingOption-icon" />,
  LinkedIn: <LinkedInIcon alt="LinkedIn" className="SharingOption-icon" />,
  Twitter: <Twitter alt="Twitter" className="SharingOption-icon" />,
  Instagram: <Instagram alt="Instagram" className="SharingOption-icon" />,
  WhatsApp: <WhatsApp alt="WhatsApp" className="SharingOption-icon" />,
  Messenger: (
    <Messenger alt="Facebook Messenger" className="SharingOption-icon" />
  ),
  Telegram: <Telegram alt="Telegram" className="SharingOption-icon" />,
  Email: <Envelope alt="Email" className="SharingOption-icon" />,
  copy: <Copy alt="Copy" className="SharingOption-icon" />,
};

export const BADGE_STYLES = {
  Email: "default",
  copy: "default",
  Facebook: "facebook",
  LinkedIn: "linkedin",
  Twitter: "twitter",
  Instagram: "instagram",
  WhatsApp: "whatsapp",
  Messenger: "messenger",
  Telegram: "telegram",
};

export const getShareableLinks = ({ title, text, url, fbAppId, isPhone }) => ({
  Email: `mailto:?subject=${title}&body=${text} ${url}`,
  Facebook: `https://web.facebook.com/dialog/share?app_id=${fbAppId}&display=page&href=${url}`,
  WhatsApp: isPhone
    ? `whatsapp://send?text=${text} ${url}`
    : `https://wa.me/?text=${text} ${url}`,
  Telegram: `https://t.me/share/url?url=${url}&text=${text}`,
  LinkedIn: `https://www.linkedin.com/sharing/share-offsite/?url=${url}`,
  Twitter: `https://twitter.com/share?url=${url}&text=${text}`,
  Messenger: isPhone
    ? `fb-messenger://share/?link=${url}&app_id=${fbAppId}`
    : `http://www.facebook.com/dialog/send?app_id=${fbAppId}&link=${url}&display=page&redirect_uri=${url}`,
});
