import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
};

const CircularCheckmark = ({ className }) => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect width="36" height="36" rx="18" fill="#F5F1FB" />
      <circle cx="18" cy="18" r="8" fill="white" />
      <path
        d="M26.6853 15.6497L25.3959 16.9594C25.4569 17.2944 25.4772 17.6497 25.4772 18.0051C25.4772 22.1269 22.1168 25.4772 17.9949 25.4772C13.8731 25.4772 10.5228 22.1269 10.5228 18.0051C10.5228 13.8832 13.8731 10.5228 17.9949 10.5228C19.8223 10.5228 21.4975 11.1827 22.797 12.2792L24.0558 11.3553C22.4518 9.8934 20.3198 9 17.9949 9C13.0305 9 9 13.0406 9 18.0051C9 22.9695 13.0305 27 17.9949 27C22.9594 27 27 22.9695 27 18.0051C27 17.1929 26.8883 16.401 26.6853 15.6497Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.9287 12.7162L17.6495 21.8939L13.3652 16.8685L14.086 16.0259L17.7206 18.4116L26.2383 11.9751L26.9287 12.7162Z"
        fill="black"
      />
    </svg>
  );
};

CircularCheckmark.propTypes = propTypes;

export default CircularCheckmark;
