import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const PetsForbidden = ({ width = 30, height = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={width}
      height={height}
    >
      <path
        fill="#fae8e8"
        d="M15,1h0A14,14,0,0,1,29,15h0A14,14,0,0,1,15,29h0A14,14,0,0,1,1,15H1A14,14,0,0,1,15,1Z"
      />
      <rect
        fill="#e9ebf2"
        opacity="0"
        x="5.49"
        y="6"
        width={width}
        height={height}
      />
      <path
        fill="#fff"
        d="M21.7,14.23l-1.78-1.17a4.17,4.17,0,0,0-4.05-3.72l-.28-2.46c-.16-.6-.93-.66-1.29.07a24.5,24.5,0,0,0-2.36,5.16c0,.16-.45,1.75-.94,3.28A14.48,14.48,0,0,1,10,18C9,19.4,8.58,19.8,8.43,21.13a1,1,0,0,0,.93,1.07l10.53.21c.45,0-1.74-3.91-1.86-4.88-.05-.37.25-.39.25-.39a4,4,0,0,0,1.79-.2c1.48-.5,1.92-1.24,2-1.78A.93.93,0,0,0,21.7,14.23Z"
      />
      <path
        fill="#fae8e8"
        stroke="#000"
        // eslint-disable-next-line react/no-unknown-property
        stoke-miterlimit="10"
        // eslint-disable-next-line react/no-unknown-property
        stoke-width="0.5"
        d="M14.41,18.07a7.89,7.89,0,0,1-3.57-1.89,10.88,10.88,0,0,1-.62,1.49A9.85,9.85,0,0,0,14,19.54a9.64,9.64,0,0,0,4.73.09c-.22-.49-.44-1-.61-1.4A8.33,8.33,0,0,1,14.41,18.07Z"
      />
      <path d="M21.84,14l-1.69-1.11a4.45,4.45,0,0,0-4-3.81L16,6.84A1.07,1.07,0,0,0,15,6a1.3,1.3,0,0,0-1.26.77A25,25,0,0,0,11.44,12c-.14.53-.5,1.91-.92,3.24a14.21,14.21,0,0,1-1,2.49l-.48.69a5,5,0,0,0-1.15,2.65,1.51,1.51,0,0,0,.37,1.16,1.39,1.39,0,0,0,1,.47H19.76a.46.46,0,0,0,.39-.18c.16-.25,0-.74-.87-2.55a14.51,14.51,0,0,1-1-2.48c0-.09,0-.1,0-.1a4.23,4.23,0,0,0,1.89-.22c1.59-.53,2.07-1.36,2.19-2A1.15,1.15,0,0,0,21.84,14Zm-3.43,5.44a9.66,9.66,0,0,1-4.32-.16,9.8,9.8,0,0,1-3.4-1.6.91.91,0,0,1-.16-.11,8.87,8.87,0,0,0,.41-1l.13.11a8.57,8.57,0,0,0,3.28,1.57,8.49,8.49,0,0,0,3.64.21Zm1.1,2.24H9.35a.39.39,0,0,1-.3-.14.49.49,0,0,1-.13-.38A4,4,0,0,1,9.9,19c.14-.21.31-.44.5-.73l.06-.1A10.2,10.2,0,0,0,14,19.78a11.14,11.14,0,0,0,2.71.35,8.8,8.8,0,0,0,2-.23l.13.28C19.05,20.66,19.32,21.25,19.51,21.7Zm.48-5a3.87,3.87,0,0,1-1.73.2.52.52,0,0,0-.37.19.62.62,0,0,0-.11.48,1.69,1.69,0,0,0,.12.45,8.14,8.14,0,0,1-3.43-.19,7.92,7.92,0,0,1-3.22-1.6l.22-.68c.43-1.35.8-2.75.95-3.31a25.48,25.48,0,0,1,2.24-5A.43.43,0,0,1,14.94,7c.16,0,.09.4.09.4,0,.23-.15.68-.3,1.33s-.42,1.8-.42,1.8a2.06,2.06,0,0,1,1.41-.93h.15a3.94,3.94,0,0,1,3.8,3.5v.12l.94.61h0c-.24.15-.71.48-.32.79a1.4,1.4,0,0,1,.27,1.85A4.67,4.67,0,0,1,20,16.69Z" />
      <path
        fill="#eb5757"
        d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM2,15A13,13,0,0,1,23.82,5.48L5.48,23.82A13,13,0,0,1,2,15ZM15,28a13,13,0,0,1-8.82-3.48L24.52,6.18A13,13,0,0,1,15,28Z"
      />
    </svg>
  );
};

PetsForbidden.propTypes = propTypes;

export default PetsForbidden;
