import * as React from "react";
import PropTypes from "prop-types";

const propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
};

export const Envelope = ({ height = "24px", width = "24px", ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19 5H5a2 2 0 00-2 2v10a2 2 0 002 2h14a2 2 0 002-2V7a2 2 0 00-2-2z"
        fill="#EDF1F4"
      />
      <path
        d="M11.47 13.49L3.33 8.36v-1.3a1.92 1.92 0 011.92-1.92h13.5a1.92 1.92 0 011.93 1.92v1.28l-8.14 5.15a1 1 0 01-1.07 0z"
        fill="#fff"
      />
      <path
        d="M19 4.25H5A2.75 2.75 0 002.25 7v10A2.76 2.76 0 005 19.75h14A2.75 2.75 0 0021.75 17V7A2.75 2.75 0 0019 4.25zM3.83 7.06a1.43 1.43 0 01.63-1.18h.06a1.38 1.38 0 01.73-.21h13.5c.261 0 .517.073.74.21h.06a1.43 1.43 0 01.63 1.18v1l-7.92 5a.47.47 0 01-.51 0l-7.92-5v-1zM20.25 17A1.25 1.25 0 0119 18.25H5A1.25 1.25 0 013.75 17V9.22l7.45 4.69c.238.164.52.252.81.25a1.38 1.38 0 00.8-.25l7.44-4.7V17z"
        fill="#24272E"
      />
    </svg>
  );
};

Envelope.propTypes = propTypes;

export default Envelope;
