import { bookingOffers, misc } from "@wunderflats/constants";

export const checkIflistingIsForRefugees = (listing) => {
  return listing?.labels?.includes(misc.labels.REFUGEE_LISTING) || false;
};

export function isRefugeeListingRequest(lr) {
  return (
    checkIflistingIsForRefugees(lr.listing) ||
    lr.reasonForStay === bookingOffers.REASON_FOR_STAY.Refugee
  );
}
