import React from "react";

const Messenger = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="url(#paint0_radial)"
      d="M12 2C6.366 2 2 6.127 2 11.7c0 2.914 1.195 5.434 3.14 7.174a.79.79 0 01.267.57l.055 1.78a.8.8 0 001.123.707l1.984-.875a.804.804 0 01.535-.04c.913.25 1.883.385 2.895.385 5.632 0 10-4.127 10-9.7 0-5.571-4.368-9.701-10-9.701z"
    />
    <path
      fill="#fff"
      d="M5.995 14.536l2.937-4.66a1.5 1.5 0 012.17-.4l2.337 1.753a.6.6 0 00.723-.002l3.154-2.395c.42-.32.97.185.69.632l-2.94 4.657a1.5 1.5 0 01-2.17.4L10.56 12.77a.6.6 0 00-.722.002l-3.155 2.395c-.42.32-.97-.182-.687-.63z"
    />
    <defs>
      <radialGradient
        id="paint0_radial"
        cx="0"
        cy="0"
        r="1"
        gradientTransform="translate(5.85 21.887) scale(21.7981)"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#09F" />
        <stop offset="0.61" stopColor="#A033FF" />
        <stop offset="0.935" stopColor="#FF5280" />
        <stop offset="1" stopColor="#FF7061" />
      </radialGradient>
    </defs>
  </svg>
);

export default Messenger;
