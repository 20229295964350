import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDevice } from "../../spages/spa/context/DeviceContext";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { canUseDOM } from "../../utils/dom";
import Popover, { placements } from "../Popover/Popover";
import CopyOption from "./CopyOption";
import SharingOption from "./SharingOption";
import { BADGE_STYLES, getShareableLinks, ICONS } from "./sharingUtils";

import "./SharingPopover.scss";

const getSharingOptions = ({
  title,
  text,
  url,
  isPhone,
  isDrawer,
  analyticsEvent,
}) => {
  const fbAppId = canUseDOM()
    ? document
        .querySelector("meta[property='fb:app_id']")
        .getAttribute("content")
    : null;
  const links = getShareableLinks({
    title,
    url,
    text,
    fbAppId,
    isPhone,
  });
  return Object.entries(links).map(([key, value]) => {
    return (
      <SharingOption
        label={key}
        key={key}
        icon={ICONS[key]}
        badgeStyle={BADGE_STYLES[key]}
        href={value}
        className={classnames({ "SharingOption--inDrawer": isDrawer })}
        analyticsEvent={analyticsEvent}
      />
    );
  });
};

const SharingPopover = ({
  isVisible,
  around,
  isDrawer,
  onClose,
  title,
  text,
  url,
  token,
  position = placements.bottom,
  subject,
  analyticsEvent,
}) => {
  const { t } = useI18n();
  const { isAPhone } = useDevice();
  return (
    <Popover
      show={isVisible}
      around={around}
      showInDrawer={isDrawer}
      placement={position}
      onClose={onClose}
      innerClassname="SharingPopover"
    >
      <h3
        className={classnames("SharingPopover-title", {
          "SharingPopover-title--inDrawer": isDrawer,
        })}
      >
        {title}
      </h3>
      <div
        className="SharingPopover-options"
        data-token={token}
        data-testid="SharingPopover-options"
      >
        {getSharingOptions({
          title: subject,
          text,
          url,
          isPhone: isAPhone,
          isDrawer,
          analyticsEvent,
        })}
        <CopyOption
          defaultLabel={t("copyLink")}
          doneLabel={t("linkCopied")}
          content={url}
          isDrawer={isDrawer}
          analyticsEvent={analyticsEvent}
        />
      </div>
    </Popover>
  );
};

SharingPopover.propTypes = {
  isVisible: PropTypes.bool,
  isDrawer: PropTypes.bool,
  around: PropTypes.node,
  onClose: PropTypes.func,
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  token: PropTypes.string,
  position: PropTypes.string,
  subject: PropTypes.string,
  analyticsEvent: PropTypes.func,
};

export default SharingPopover;
