import React, { forwardRef, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import dayjs from "dayjs";
import DateInput from "../../../../components/DateInput/DateInput";
import linkDateInputs from "../../../../components/DateInput/link";
import { Button } from "../../../../components/DesignSystem/Button/Button";
import FormHelpText from "../../../../components/FormHelpText/FormHelpText";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import { prepareModifiersForClassNames } from "../../../../utils/bem";
import { getMaxBookableDate } from "../../../../utils/fromToDatesValidation";

const propTypes = {
  t: PropTypes.func.isRequired,
  siblingsCount: PropTypes.number.isRequired,
  onReset: PropTypes.func.isRequired,
  datesAvailable: PropTypes.bool,
  onBookingSubmit: PropTypes.func.isRequired,
  minBookingDuration: PropTypes.number.isRequired,
  maxBookingDuration: PropTypes.number,
  onSubmitDates: PropTypes.func,
  onSetBookingFrom: PropTypes.func,
  onSetBookingTo: PropTypes.func,
  bookingFrom: PropTypes.string,
  bookingTo: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.string,
  previouslyRequested: PropTypes.bool,
  ownListing: PropTypes.bool,
};

const BookingForm = forwardRef(
  (
    {
      t,
      datesAvailable,
      previouslyRequested,
      ownListing,
      maxBookingDuration,
      bookingFrom,
      bookingTo,
      onSubmitDates,
      onReset,
      onBookingSubmit,
      onSetBookingFrom,
      onSetBookingTo,
      minBookingDuration,
      loading,
      siblingsCount,
      error,
    },
    ref,
  ) => {
    useEffect(() => {
      if (bookingFrom && bookingTo) {
        onSubmitDates({ scrollToResponse: false });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmitDatesForm = (submitEvent) => {
      submitEvent.preventDefault();
      onSubmitDates();
    };

    const renderForm = () => {
      const maxDateTo = maxBookingDuration
        ? getMaxBookableDate({
            fromDate: bookingFrom,
            maxBookingDuration,
          })
        : undefined;

      if (previouslyRequested || datesAvailable === false) {
        return (
          <div className="Multilisting-BookingForm-content-inputs">
            <button className="btn btn-secondary" onClick={onReset}>
              {t(
                "spages.multilisting.MultilistingDetailsPage.form.changeDates",
              )}
            </button>
          </div>
        );
      }

      if (datesAvailable === true) {
        return (
          <div className="Multilisting-BookingForm-content-inputs">
            <button
              disabled={ownListing}
              className="btn btn-action Multilisting-BookingForm-submit"
              onClick={() =>
                onBookingSubmit({
                  from: bookingFrom,
                  to: bookingTo,
                })
              }
            >
              <span className="Multilisting-BookingForm-submit-text">
                {t("spages.multilisting.MultilistingDetailsPage.form.bookNow")}
              </span>
            </button>
            <button className="btn btn-secondary" onClick={onReset}>
              {t(
                "spages.multilisting.MultilistingDetailsPage.form.changeDates",
              )}
            </button>
          </div>
        );
      }

      const dateInputs = linkDateInputs(
        <DateInput
          t={t}
          label={t("spages.multilisting.MultilistingDetailsPage.form.from")}
          name="from"
          minDate={dayjs.utc().toISOString()}
          value={bookingFrom}
          onChange={onSetBookingFrom}
          inlineLabel
        />,
        <DateInput
          t={t}
          label={t("spages.multilisting.MultilistingDetailsPage.form.to")}
          name="to"
          minDate={dayjs.utc().add(minBookingDuration, "months").toISOString()}
          maxDate={maxDateTo}
          value={bookingTo}
          onChange={onSetBookingTo}
          inlineLabel
        />,
        { offset: [minBookingDuration, "month"], autoSelect: true },
      );

      return (
        <form
          className="Multilisting-BookingForm-content-inputs"
          onSubmit={onSubmitDatesForm}
        >
          {dateInputs.from}
          {dateInputs.to}
          <Button
            size="medium"
            className="btn btn-action Multilisting-BookingForm-submit"
            type="submit"
            loading={loading}
            spinnerProps={{ showOverlay: false }}
            disabled={loading}
          >
            {t(
              "spages.multilisting.MultilistingDetailsPage.form.checkAvailability",
            )}
          </Button>
        </form>
      );
    };

    const renderStatusIcon = () => {
      if (previouslyRequested || datesAvailable === true) {
        return <i className="icon icon--tick" />;
      }

      if (datesAvailable === false) {
        return <i className="icon icon--block" />;
      }

      if (loading) {
        return <LoadingSpinner showOverlay={false} />;
      }

      return <i className="icon icon--calendar" />;
    };

    if (siblingsCount <= 0) return null;

    const bookingFromFormatted = dayjs
      .utc(bookingFrom, "YYYY-MM-DD")
      .format("DD.MM.YYYY");

    const bookingToFormatted = dayjs
      .utc(bookingTo, "YYYY-MM-DD")
      .format("DD.MM.YYYY");

    let title = "";
    let subtitle = "";

    if (previouslyRequested) {
      title = t(
        "spages.multilisting.MultilistingDetailsPage.form.alreadyRequested",
      );
      subtitle = null;
    } else if (datesAvailable === true) {
      title = t(
        "spages.multilisting.MultilistingDetailsPage.form.success.title",
      );
      subtitle = (
        <span>
          {t(
            "spages.multilisting.MultilistingDetailsPage.form.success.subtitle",
          )}{" "}
          <strong>
            {bookingFromFormatted} - {bookingToFormatted}
          </strong>
        </span>
      );
    } else if (datesAvailable === false) {
      title = t("spages.multilisting.MultilistingDetailsPage.form.fail.title");
      subtitle = (
        <span>
          {t("spages.multilisting.MultilistingDetailsPage.form.fail.subtitle")}{" "}
          <strong>
            {bookingFromFormatted} - {bookingToFormatted}
          </strong>
        </span>
      );
    } else {
      title = t("spages.multilisting.MultilistingDetailsPage.form.title");
      subtitle = t(
        "spages.multilisting.MultilistingDetailsPage.form.subtitle",
        {
          count: siblingsCount,
        },
      );
    }
    // Multilisting-BookingForm
    const componentClass = classnames(
      "Multilisting-BookingForm",
      prepareModifiersForClassNames("Multilisting-BookingForm", {
        loading,
      }),
    );

    const messageClass = classnames(
      "Multilisting-BookingForm-Message",
      prepareModifiersForClassNames("Multilisting-BookingForm", {
        error: !!error,
      }),
    );

    return (
      <div className={componentClass} ref={ref}>
        <div className="Multilisting-BookingForm-Icon-outer">
          <div className="Multilisting-BookingForm-Icon">
            {renderStatusIcon()}
          </div>
        </div>
        <div className="Multilisting-BookingForm-content">
          <h3 className="Multilisting-BookingForm-content-title">{title}</h3>
          <h4 className="Multilisting-BookingForm-content-subtitle">
            {subtitle}
          </h4>
          {renderForm()}
          {ownListing && (
            <FormHelpText>{t("youCannotRequestYourOwnListing")}</FormHelpText>
          )}
          <div className={messageClass}>
            {error &&
              t(
                `spages.listing.ListingDetailsPageBase.StartCreateBookingWizardForm.errors.${error}`,
              )}
          </div>
        </div>
      </div>
    );
  },
);

BookingForm.propTypes = propTypes;

export default BookingForm;
