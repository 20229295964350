import React from "react";

const Telegram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#29AAEC"
      fillRule="evenodd"
      d="M1.375 11.135l10.74-4.626c5.114-2.127 6.177-2.497 6.87-2.509.152-.003.493.035.713.214.187.151.238.356.262.499.025.143.055.47.031.725-.277 2.912-1.476 9.979-2.086 13.24-.259 1.38-.767 1.843-1.259 1.888-1.07.099-1.882-.706-2.917-1.386l-4.11-2.76c-1.818-1.198-.64-1.857.396-2.933.272-.282 4.984-4.568 5.075-4.957.01-.048.022-.23-.086-.325-.107-.096-.266-.063-.381-.037-.163.037-2.752 1.748-7.768 5.134-.735.505-1.4.75-1.997.738-.658-.014-1.923-.372-2.863-.677-1.153-.375-2.07-.574-1.99-1.21.041-.332.498-.671 1.37-1.018z"
      clipRule="evenodd"
    />
  </svg>
);

export default Telegram;
