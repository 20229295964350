import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const FloorPlan = ({ width = 18, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <rect fill="#e9ebf2" opacity="0" width={width} height={height} />
      <path
        fill="#fff"
        d="M9.22,16H3a1,1,0,0,1-1-1V3A1,1,0,0,1,3,2H15a1,1,0,0,1,1,1V15a1,1,0,0,1-1,1H11.81"
      />
      <path
        fill="#24272e"
        d="M15,1H3A2,2,0,0,0,1,3V15a2,2,0,0,0,2,2H9.21V15H3V10H5.57V8.47H3V3H9.88V6.79H11.4V3H15V8.47H7.74V10H15v5H11.81v2H15a2,2,0,0,0,2-2V3A2,2,0,0,0,15,1Z"
      />
    </svg>
  );
};

FloorPlan.propTypes = propTypes;

export default FloorPlan;
