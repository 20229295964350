import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useI18n } from "../../spages/spa/context/I18nContext";

// Configuration of the widget can be found here: https://wunderflats.freshdesk.com/a/admin/widgets/75000000153

/**
 * Explaination for the FreshworksWidget usage below
 *
 * Hide the 'cf_pageurl_v2' field, which will be auto-filled with the current page URL,
 * but should not be displayed on the form.
 *
 * Note: The customFields and custom_fields have different effects on the Freshdesk Help widget.
 * To hide a field, use custom_fields (underscore format). To prefill a field, use customFields (camelCase format).
 */

const WIDGET_ID = "75000000153";

const FreshdeskHelpWidget = ({ isButtonVisible = true }) => {
  const currentUrl = typeof window !== "undefined" ? window.location.href : "";

  const { t, lang } = useI18n();

  React.useEffect(() => {
    if (!window?.FreshworksWidget) {
      return;
    }

    if (isButtonVisible) {
      // eslint-disable-next-line new-cap
      window.FreshworksWidget("show", "launcher");
    }

    if (!isButtonVisible) {
      // eslint-disable-next-line new-cap
      window.FreshworksWidget("hide", "launcher");
    }
  }, [isButtonVisible]);

  React.useEffect(() => {
    return () => {
      if (window?.FreshworksWidget) {
        // eslint-disable-next-line new-cap
        window.FreshworksWidget("hide");
      }
    };
  }, []);

  return (
    <Helmet>
      <script>
        {`
          window.fwSettings={'widget_id':${WIDGET_ID},'locale':'${lang}'};
          !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()
        `}
      </script>
      <script
        type="text/javascript"
        src={`https://euc-widget.freshworks.com/widgets/${WIDGET_ID}.js`}
        async
        defer
      />
      <script type="text/javascript">
        {`
          FreshworksWidget("setLabels", {
            '${lang}': {
              banner: "${t("freshworksHelpWidget.banner")}",
              launcher: "${t("freshworksHelpWidget.launcher")}",
              contact_form: {
                title: "${t("freshworksHelpWidget.contactForm.title")}",
                submit: "${t("freshworksHelpWidget.contactForm.submit")}",
                confirmation: "${t(
                  "freshworksHelpWidget.contactForm.confirmation",
                )}",
              }
            }
          });
          FreshworksWidget("prefill", "ticketForm", { "customFields.cf_pageurl_v2": "${currentUrl}" });
          FreshworksWidget("hide", "ticketForm", ["custom_fields.cf_pageurl_v2"]);
        `}
      </script>
    </Helmet>
  );
};

FreshdeskHelpWidget.propTypes = {
  isButtonVisible: PropTypes.bool,
};

export default FreshdeskHelpWidget;
