import React from "react";
import PropTypes from "prop-types";
import loadable from "@loadable/component";
import { listings, misc } from "@wunderflats/constants";
import classnames from "classnames";
import dayjs from "dayjs";
import qs from "qs";
import store from "store2";
import analytics from "../../../../analytics";
import { INTERNAL_REFERRER_SESSION_STORAGE_KEY } from "../../../../analytics/constants";
import AppHeader from "../../../../components/AppHeader/AppHeader";
import ComponentLazyLoad from "../../../../components/ComponentLazyLoad";
import CoverImage from "../../../../components/CoverImage/CoverImage";
import {
  Button,
  Link,
} from "../../../../components/DesignSystem/Button/Button";
import Footer from "../../../../components/Footer/Footer";
import FreshdeskHelpWidget from "../../../../components/FreshdeskHelpWidget/FreshdeskHelpWidget";
import CircularCheckmark from "../../../../components/Icons/CircularCheckmark";
import FloorPlan from "../../../../components/Icons/FloorPlan";
import Gallery from "../../../../components/Icons/Gallery";
import LocationMarker from "../../../../components/Icons/LocationMarker";
import PetsAllowed from "../../../../components/Icons/PetsAllowed";
import PetsForbidden from "../../../../components/Icons/PetsForbidden";
import SmokingAllowed from "../../../../components/Icons/SmokingAllowed";
import SmokingForbidden from "../../../../components/Icons/SmokingForbidden";
import StudentCap from "../../../../components/Icons/StudentCap";
import MultilistingBadge from "../../../../components/MultilistingBadge/MultilistingBadge";
import ServicedApartmentBadge from "../../../../components/ServicedApartmentBadge/ServicedApartmentBadge";
import ShareButton from "../../../../components/Share/ShareButton";
import SimilarListings from "../../../../components/SimilarListings/SimilarListings";
import FeatureFlagContext from "../../../../contexts/FeatureFlagContext";
import { getRoutes } from "../../../../routes";
import { funnelKey } from "../../../../tracker/constants";
import getCoverImageData from "../../../../utils/cover-image";
import dataLayerUtils from "../../../../utils/data-layer";
import * as defaultListingFilter from "../../../../utils/default-listing-filter";
import { canUseDOM } from "../../../../utils/dom";
import {
  getEarliestAvailableDate,
  isFromDateAvailable,
  isPeriodAvailable,
  isToDateAvailable,
} from "../../../../utils/fromToDatesValidation";
import isFeatureFlagEnabled from "../../../../utils/isFeatureFlagEnabled";
import {
  getApartmentAddress,
  isServicedApartment,
} from "../../../../utils/listing-utils";
import { create as createListingViewModel } from "../../../../utils/listing-view-model";
import { extractLanguageContent } from "../../../../utils/localizer";
import { formatCurrency } from "../../../../utils/prices";
import { checkIflistingIsForRefugees } from "../../../../utils/refugee";
import sanitizeURL from "../../../../utils/sanitize-url";
import slug from "../../../../utils/slug";
import { relativeToFullyQualified, urlMaker } from "../../../../utils/url";
import getWFCONFIG from "../../../../utils/WF_CONFIG";
import { withI18n } from "../../context/I18nContext";
import { withTracker } from "../../context/TrackerContext";
import PageHead from "../../PageHead";
import AmenitiesList from "./AmenitiesList";
import arie from "./arie";
import BedsList from "./BedsList";
import BookingForm from "./BookingForm";
import CostOverview from "./CostOverview";
import dataLayer from "./data-layer";
import ImageGalleryModal from "./ImageGalleryModal/ImageGalleryModal";
import MobileCallOut from "./MobileCallOut/MobileCallOut";
import MonthlyAvailabilityViewer from "./MonthlyAvailabilityViewer/MonthlyAvailabilityViewer";
import MultilistingUnavailableCard from "./MultilistingUnavailableCard/MultilistingUnavailableCard";
import PermitIdComponent from "./PermitIdComponent";
import RegistrationNumber from "./RegistrationNumber";
import ServicesList from "./ServicesList";
import StartCreateBookingWizardForm from "./StartCreateBookingWizardForm/StartCreateBookingWizardForm";
import structuredData from "./structured-data";
import VerifiedBadge from "./VerifiedBadge";
import ViennaStayDurationMessage from "./ViennaStayDurationMessage";
import "./ListingDetailsPage.scss";

const WF_CONFIG = getWFCONFIG();

const { labels, FEATURE_FLAGS } = misc;

const {
  HOME_TYPES,
  APARTMENT_RULES,
  energyCertificates,
  MAX_BOOKING_DURATION,
} = listings;

const VIRTUAL_TOUR_VALID_DOMAIN = "my.matterport.com";

const BOOKING_TEAM_IMAGE_SRC =
  "/public/images/listing-details-page/bookingTeam.webp";

const ListingMarker = loadable(
  () => import("../../../../components/ListingMarker/ListingMarker"),
  { ssr: false },
);

const Map = loadable(
  () => import("../../../../components/MapContainer/MapContainer"),
  { ssr: false },
);

const MapTilerLogo = loadable(
  () => import("../../../../components/MapContainer/MapTilerLogo"),
  { ssr: false },
);

const ShortlistButton = loadable(
  () => import("../../../../components/Shortlist/ShortlistButton"),
  {
    ssr: false,
  },
);

class ListingsDetailsPage extends React.PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
    lang: PropTypes.string.isRequired,
    url: PropTypes.func.isRequired,
    api: PropTypes.object, // browser-only
    analytics: PropTypes.object, // browser-only
    user: PropTypes.object,
    pageData: PropTypes.shape({
      listing: PropTypes.object.isRequired,
      blocks: PropTypes.array,
      cookies: PropTypes.object,
      totalRent: PropTypes.number.isRequired,
      categories: PropTypes.arrayOf(PropTypes.object),
    }),
    params: PropTypes.object,
    query: PropTypes.object,
    translationUrls: PropTypes.object.isRequired,
    currentUrl: PropTypes.string.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func, // browser-only
      replace: PropTypes.func, // browser-only
      location: PropTypes.object,
    }),
    location: PropTypes.object,
    featureFlags: PropTypes.array,
    isAPhone: PropTypes.bool,
    extractContent: PropTypes.func.isRequired,
    tracker: PropTypes.object.isRequired,
  };

  constructor(props) {
    super();
    const {
      t,
      lang,
      query,
      analytics,
      pageData: { listing, blocks, cookies },
    } = props;
    const defaultFilters = defaultListingFilter.get();
    this.lvm = createListingViewModel(t, lang);
    this.followScrollActive = false;
    this.mobileFixedButtonsRef = React.createRef();
    this.bookingFormRef = React.createRef();
    this.bookingSalesRef = React.createRef();
    this.multilistingBookingFormRef = React.createRef();
    this.dateRangePickerRef = React.createRef();

    if (analytics) {
      this.event = analytics.category("ListingDetailsPage");
    } else {
      this.event = () => {};
    }

    if (!listing.partOfGroup) {
      this.listingIdForSimilarListings = listing._id;
    } else if (listing.group && listing.group.length > 0) {
      const firstPublishedListing = listing.group.find(
        (listing) => listing.published,
      );

      if (firstPublishedListing) {
        this.listingIdForSimilarListings = firstPublishedListing._id;
      }
    }

    let fromDate = "";
    if (
      isFromDateAvailable({
        blocks,
        date: query.from,
        minBookingDuration: listing.minBookingDuration,
      })
    ) {
      fromDate = query.from;
    } else if (
      isFromDateAvailable({
        blocks,
        date: defaultFilters.from,
        minBookingDuration: listing.minBookingDuration,
      })
    ) {
      fromDate = defaultFilters.from;
    }

    let toDate;

    if (fromDate) {
      const isQueryToAvailable = isToDateAvailable({
        from: fromDate,
        blocks,
        minBookingDuration: listing.minBookingDuration || 1,
        maxBookingDuration: listing.maxBookingDuration,
        date: query.to,
      });

      if (isQueryToAvailable) {
        toDate = query.to;
      } else if (
        isToDateAvailable({
          from: fromDate,
          blocks,
          minBookingDuration: listing.minBookingDuration || 1,
          maxBookingDuration: listing.maxBookingDuration,
          date: defaultFilters.to,
        })
      ) {
        toDate = defaultFilters.to;
      } else {
        toDate = "";
      }
    }

    this.state = {
      sidebarClassName: "ListingDetails-bookingSidebar--stickyTop",
      datesAvailable: null,
      bookingFrom: fromDate,
      bookingTo: toDate,
      datesRequestLoading: false,
      isImpersonatingUser: canUseDOM() ? cookies.tiramisu === "true" : false,
      defaultFilters,
      showingImageGallery: false,
      canListingBeRequested: {
        reason: null,
        requesting: false,
        status: null,
      },
      descriptionExpanded: false,
      additionalInfoExpanded: false,
      smallScreen: props.isAPhone,
      isContactUsWidgetVisible: !props.isAPhone,
    };

    if (listing.minBookingDuration) {
      const a = arie({
        availableFrom: listing.availableFrom,
        availableTo: listing.availableTo,
        blockedDurations: blocks,
        minBookingDuration: listing.minBookingDuration,
      });

      this.state.blockedDurations = a.blockedDurations();
      this.state.firstPossibleCheckin = a.firstPossibleCheckin();
    }
  }

  getReadMore = (fullContent, expandedProp, elementId = null, limit = 500) => {
    if (fullContent.length <= limit) return <span>{fullContent}</span>;
    const shortContent = `${fullContent?.substring(0, limit)}...`;
    const expanded = this.state[expandedProp];
    const { t } = this.props;

    return (
      <div>
        <p>{expanded ? fullContent : shortContent}</p>
        <a
          className="ListingDetails-readMoreToggle"
          onClick={this.handleReadMoreButton(expandedProp, elementId)}
        >
          {expanded ? t("readLess") : t("readMore")}
        </a>
      </div>
    );
  };

  handleReadMoreButton = (expandedProp, elementId) => () => {
    const expanded = this.state[expandedProp];

    if (expanded && elementId)
      document.getElementById(elementId).scrollIntoView();

    this.setState({ [expandedProp]: !expanded });
  };

  async checkListingCanBeRequested({ from = null, to = null } = {}) {
    const { listing } = this.props.pageData;

    if ((!from || !to) && (!this.state.bookingFrom || !this.state.bookingTo)) {
      return;
    }

    let listingId = listing._id;
    if (listing.partOfGroup) {
      try {
        const availableListingIdsInGroup =
          await this.props.api.multilistings.getAvailableListings(
            listing.groupId,
            {
              from: dayjs(from || this.state.bookingFrom).format("YYYY-MM-DD"),
              to: dayjs(to || this.state.bookingTo).format("YYYY-MM-DD"),
            },
          );

        listingId = availableListingIdsInGroup[0]._id;
      } catch (error) {
        listingId = listing.group[0]._id;
        window.rollbar?.error(error);
      }
    }

    this.setState(
      {
        canListingBeRequested: {
          ...this.state.canListingBeRequested,
          requesting: true,
        },
      },
      () => {
        this.props.api.listings
          .canListingBeRequested({
            listingId,
            from: dayjs(from || this.state.bookingFrom).format("YYYY-MM-DD"),
            to: dayjs(to || this.state.bookingTo).format("YYYY-MM-DD"),
          })
          .then((response) => {
            this.setState({
              canListingBeRequested: {
                status: response.status,
                reason: response.reason || null,
                requesting: false,
              },
            });
          })
          .catch((error) => {
            this.setState({
              canListingBeRequested: {
                status: null,
                reason: null,
                requesting: false,
              },
            });

            window.rollbar?.error(error);
          });
      },
    );
  }

  isSmallScreenMedia = null;

  isSmallScreenMediaHandler = (evt) => {
    if (evt.matches) {
      this.setState({ smallScreen: true });
    } else {
      this.setState({ smallScreen: false });
    }
  };

  componentDidMount() {
    const isSmallScreenMedia = window.matchMedia("(max-width: 993px)");
    this.isSmallScreenMedia = isSmallScreenMedia;
    isSmallScreenMedia.addEventListener(
      "change",
      this.isSmallScreenMediaHandler,
    );

    // implementing booking sidebar
    if ("IntersectionObserver" in window && !isSmallScreenMedia.matches) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (!entries[0].isIntersecting) {
            this.setState({
              sidebarClassName: "ListingDetails-bookingSidebar--stickyFix",
            });
          } else {
            this.setState({
              sidebarClassName: "ListingDetails-bookingSidebar--stickyTop",
            });
          }
        },
        { rootMargin: "100px" },
      );

      observer.observe(document.querySelector(".CoverImage"));
      this.bookingSidebarObserver = observer;
    }

    this.isSmallScreenMediaHandler({ matches: isSmallScreenMedia.matches });

    this.checkListingCanBeRequested();

    this.trackListingPageView(this.props.pageData.listing);

    dataLayerUtils.pushToDataLayer(
      dataLayer.getDataLayerVariables("Listing Details", {
        pageType: "Listing Details",
        user: this.props.user,
        listing: this.props.pageData.listing,
        query: this.props.query,
        bookingRequestId:
          (this.state && this.state.bookingRequestId) || undefined,
        totalRent: this.props.pageData.totalRent,
      }),
    );

    if (this.props.params.groupId) {
      dataLayerUtils.pushToDataLayer(
        dataLayer.getDataLayerVariables("Listing Details", {
          pageType: "offerdetails",
          user: this.props.user,
          listing: this.props.pageData.listing,
          query: this.props.query,
          bookingRequestId:
            (this.state && this.state.bookingRequestId) || undefined,
          totalRent: this.props.pageData.totalRent,
        }),
      );
    }

    dataLayerUtils.pushToDataLayer({ event: "offer-details-page-loaded" });
  }

  componentDidUpdate(prevProps) {
    // User is now logged in
    if (!prevProps.user && this.props.user) {
      const { from, to } = this.props.query;

      if (from && to) {
        this.checkListingCanBeRequested({ from, to });
      } else {
        this.checkListingCanBeRequested();
      }
    }

    // User is now logged out
    if (prevProps.user && !this.props.user) {
      this.checkListingCanBeRequested();
    }

    dataLayerUtils.pushToDataLayer({ event: "offer-details-page-updated" });
    if (
      prevProps.pageData.listing.groupId !== this.props.pageData.listing.groupId
    ) {
      this.trackListingPageView(this.props.pageData.listing);
    }
  }

  componentWillUnmount() {
    if (this.listingImageObserver) {
      this.listingImageObserver.disconnect();
    }
    if (this.isSmallScreenMedia) {
      this.isSmallScreenMedia.removeEventListener(
        "change",
        this.isSmallScreenMediaHandler,
      );
    }
    if (this.bookingSidebarObserver && this.bookingSidebarObserver.disconnect) {
      this.bookingSidebarObserver.disconnect();
    }
  }

  trackListingPageView = (listing) => {
    const { api } = this.props;
    if (!this.state.isImpersonatingUser) {
      const referrer = store.get(INTERNAL_REFERRER_SESSION_STORAGE_KEY);

      api.analytics
        .createListingDetailsViewedEvent(listing.groupId, {
          referrer: referrer?.r,
          collection: referrer?.c,
        })
        .then(() => {
          store.remove(INTERNAL_REFERRER_SESSION_STORAGE_KEY);
        });
    }
  };

  onCloseGallery = () => {
    const {
      pageData: { listing },
    } = this.props;
    this.event("close gallery", listing._id);
    this.setState({ showingImageGallery: false });
  };

  onSetBookingFrom = (value) => {
    const listingId = this.props.pageData.listing._id;
    defaultListingFilter.set("from", value);
    // if there's no fromDate, there shouldn't be any to date
    if (!value) {
      this.setState({ bookingTo: "", bookingFrom: "" });
      return;
    }

    this.setState({ bookingFrom: value }, () => {
      const { bookingFrom: from, bookingTo: to } = this.state;

      if (from && to) {
        this.checkListingCanBeRequested({ from, to });
        this.datesSubmit({ scrollToResponse: false });
      } else {
        this.setState({
          listingCanBeRequested: {
            status: null,
            reason: null,
            requesting: false,
          },
        });
      }
    });

    this.event("set booking from", listingId);
  };

  onSetBookingTo = (value) => {
    const {
      pageData: { listing },
    } = this.props;
    const listingId = listing._id;
    defaultListingFilter.set("to", value);
    this.setState({ bookingTo: value }, () => {
      const { bookingFrom: from, bookingTo: to } = this.state;

      if (from && to) {
        this.checkListingCanBeRequested({ from, to });
      } else {
        this.setState({
          listingCanBeRequested: {
            status: null,
            reason: null,
            requesting: false,
          },
        });
      }
    });

    this.event("set booking to", listingId);
  };

  // TODO: WRITE A BATTERY OF TESTS FOR THE LOGIC HERE
  onClickMobileCallOut = async () => {
    const { bookingFrom: from, bookingTo: to, datesAvailable } = this.state;
    const {
      pageData: { listing },
    } = this.props;

    if (listing.partOfGroup) {
      if (datesAvailable === false) {
        await this.resetDates();
        this.dateRangePickerRef?.current.focus();
        return;
      }
      if (datesAvailable === true) {
        this.startBookingWizard({ from, to });
        return;
      }
    }

    const userHasSetFromDate = !!from;
    const userHasSetToDate = !!to;

    if (userHasSetFromDate && userHasSetToDate) {
      return this.startBookingWizard({ from, to });
    }

    if (!userHasSetFromDate || !userHasSetToDate) {
      this.dateRangePickerRef?.current.focus();
    }
  };

  showImageGallery = () => {
    const {
      pageData: { listing },
    } = this.props;
    this.event(
      "show gallery",
      listing.partOfGroup ? listing.groupId : listing._id,
    );
    this.setState({ showingImageGallery: true });
  };

  startBookingWizard = (duration) => {
    const {
      pageData: { listing },
      tracker,
      user,
    } = this.props;
    const userId = user?._id;

    if (!duration.from || !duration.to) {
      return this.setState({ bookingError: "incomplete" });
    }

    const query = {
      from: duration.from,
      to: duration.to,
    };

    const urlToRedirect = this.props.url("book", {
      stepNumber: "1",
      groupId: listing.groupId,
    });
    const queryString = qs.stringify(query);

    this.event("start booking process", listing._id);
    tracker.generateFunnelId({ funnelKey: funnelKey.REQUEST_TO_BOOK });

    this.setState({
      duration,
      from: duration.from,
      to: duration.to,
    });

    tracker.events.trackListingOnLdpRequestToBookButtonClicked({
      tenantUserId: userId,
      groupId: listing.groupId,
      fromDate: duration.from,
      toDate: duration.to,
    });

    window.location = sanitizeURL(`${urlToRedirect}?${queryString}`);
  };

  onDatesSubmit = async (from, to) => {
    if (!from || !to) {
      this.setState({ datesRequestError: "incomplete" });
      return;
    }

    const {
      pageData: { listing },
    } = this.props;

    this.setState({
      datesRequestLoading: true,
      datesRequestError: null,
      datesAvailable: null,
    });

    let newState;
    try {
      const listings = await this.props.api.multilistings.getAvailableListings(
        listing.groupId,
        {
          from,
          to,
        },
      );

      newState = {
        datesAvailable: listings.length > 0,
      };
    } catch (error) {
      newState = {
        datesRequestError: error,
      };
    }

    this.setState({ ...newState, datesRequestLoading: false });
    return newState;
  };

  onResetDates = async () => {
    this.setState({
      datesAvailable: null,
      datesRequestError: null,
    });
  };

  resetDates = async () => {
    const partOfGroup = this.props.pageData.listing.partOfGroup;
    if (partOfGroup) {
      await this.onResetDates();
    }

    this.setState({
      canListingBeRequested: {
        status: null,
        reason: null,
        requesting: false,
      },
    });
  };

  datesSubmit = async ({ scrollToResponse = true } = {}) => {
    const { bookingFrom, bookingTo } = this.state;
    if (!this.onDatesSubmit) {
      throw new Error("Should pass onDatesSubmit prop");
    }

    const { datesAvailable } = await this.onDatesSubmit(bookingFrom, bookingTo);

    if (!datesAvailable) {
      this.setState({
        canListingBeRequested: {
          status: false,
          reason: null,
          requesting: false,
        },
      });
    }

    if (
      !scrollToResponse ||
      document.body.offsetWidth <= 991 ||
      this.state.smallScreen
    ) {
      return;
    }

    const multilistingBookingForm = this.multilistingBookingFormRef?.current;

    if (!multilistingBookingForm) return;

    multilistingBookingForm.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  showTenantServiceFeeOnRequestToBookSection = () => {
    const {
      pageData: { listing },
    } = this.props;

    return !checkIflistingIsForRefugees(listing);
  };

  regularListingBookingWizardSubmitLabel = () => {
    const { t } = this.props;

    return t("spages.listing.ListingDetailsPageBase.requestToBook");
  };

  renderDateRequestForm() {
    const {
      t,
      pageData: { listing },
    } = this.props;
    const {
      datesRequestError,
      datesRequestLoading,
      datesAvailable,
      bookingFrom,
      bookingTo,
      canListingBeRequested,
    } = this.state;

    return (
      <BookingForm
        t={t}
        ref={this.multilistingBookingFormRef}
        siblingsCount={listing.group.length}
        onReset={this.resetDates}
        onSubmitDates={this.datesSubmit}
        onBookingSubmit={this.startBookingWizard}
        datesAvailable={datesAvailable}
        onSetBookingFrom={this.onSetBookingFrom}
        onSetBookingTo={this.onSetBookingTo}
        bookingFrom={bookingFrom}
        bookingTo={bookingTo}
        loading={datesRequestLoading || canListingBeRequested.requesting}
        ownListing={this.isOwnListing()}
        minBookingDuration={listing.minBookingDuration || 1}
        maxBookingDuration={listing.maxBookingDuration}
        error={datesRequestError}
      />
    );
  }

  renderCalendar() {
    const { availableFrom, availableTo } = this.props.pageData.listing;

    return (
      <MonthlyAvailabilityViewer
        minOffset={0}
        maxOffset={2}
        blocks={this.state.blockedDurations}
        availableFrom={availableFrom}
        availableTo={availableTo}
      />
    );
  }

  handleDatePickerVisible = (isDatePickerVisible) => {
    this.setState({ isContactUsWidgetVisible: !isDatePickerVisible });
  };

  renderStartCreateBookingWizardFormMultilisting() {
    const {
      bookingFrom,
      bookingTo,
      bookingError,
      datesAvailable,
      blockedDurations,
      datesRequestError,
      smallScreen,
    } = this.state;

    const {
      t,
      pageData: { listing },
    } = this.props;

    const minBookingDuration =
      this.props.pageData.listing.minBookingDuration || 1;

    return (
      <StartCreateBookingWizardForm
        isMultilisting={listing.partOfGroup}
        ref={this.bookingFormRef}
        availableFrom={listing.availableFrom}
        from={{
          onChange: this.onSetBookingFrom,
          value: bookingFrom,
        }}
        to={{
          onChange: this.onSetBookingTo,
          value: bookingTo,
        }}
        available={datesAvailable === null ? true : datesAvailable}
        onReset={this.resetDates}
        loading={
          this.state.datesRequestLoading ||
          this.state.canListingBeRequested.requesting
        }
        onSubmit={datesAvailable ? this.startBookingWizard : this.datesSubmit}
        minBookingDuration={minBookingDuration}
        maxBookingDuration={listing.maxBookingDuration}
        error={bookingError || datesRequestError}
        blockedDurations={blockedDurations}
        submitButtonText={
          datesAvailable
            ? t("spages.multilisting.MultilistingDetailsPage.form.bookNow")
            : t(
                "spages.multilisting.MultilistingDetailsPage.form.checkAvailability",
              )
        }
        ownListing={this.isOwnListing()}
        price={listing.price}
        currency={listing.currency}
        canListingBeRequested={this.state.canListingBeRequested}
        isTenantServiceFeeVisible={this.showTenantServiceFeeOnRequestToBookSection()}
        isSmallScreen={smallScreen}
        onDatePickerVisible={(value) => this.handleDatePickerVisible(value)}
      />
    );
  }

  renderStartCreateBookingWizardFormSingleListing() {
    const {
      bookingFrom,
      bookingTo,
      blockedDurations,
      bookingError,
      smallScreen,
    } = this.state;

    const {
      pageData: { listing },
    } = this.props;

    const { availableFrom, availableTo, minBookingDuration } = listing;

    const available = isPeriodAvailable({
      availableFrom,
      availableTo,
      bookingFrom,
      bookingTo,
      blockedDurations,
    });

    return (
      <StartCreateBookingWizardForm
        ref={this.bookingFormRef}
        availableTo={availableTo}
        availableFrom={listing.availableFrom}
        from={{
          onChange: this.onSetBookingFrom,
          value: bookingFrom,
        }}
        to={{
          onChange: this.onSetBookingTo,
          value: bookingTo,
        }}
        loading={this.state.canListingBeRequested.requesting}
        canListingBeRequested={this.state.canListingBeRequested}
        available={available}
        onSubmit={this.startBookingWizard}
        minBookingDuration={minBookingDuration || 1}
        maxBookingDuration={listing.maxBookingDuration}
        error={bookingError}
        blockedDurations={blockedDurations}
        submitButtonText={this.regularListingBookingWizardSubmitLabel()}
        ownListing={this.isOwnListing()}
        price={listing.price}
        currency={listing.currency}
        country={listing.address?.country}
        zipCode={listing.address?.zipCode}
        isTenantServiceFeeVisible={this.showTenantServiceFeeOnRequestToBookSection()}
        isSmallScreen={smallScreen}
        onDatePickerVisible={(value) => this.handleDatePickerVisible(value)}
      />
    );
  }

  isOwnListing() {
    const {
      user,
      pageData: { listing },
    } = this.props;

    if (!user) {
      return false;
    }

    if (!listing.landlord) {
      return false;
    }

    const landlordId = listing.landlord._id || listing.landlord;

    return user._id === landlordId;
  }

  tryingToBook = () => {
    const { params } = this.props;

    // booking wizard only has listingId (`:id`) and `:step` set
    return !params.bookingRequestId && !!params.step;
  };

  showFreshdeskWidget() {
    // eslint-disable-next-line new-cap
    window.FreshworksWidget("open");
  }

  render() {
    const lvm = this.lvm;
    const {
      t,
      lang,
      pageData: { listing, cookies, categories },
      url,
      user,
      query,
      params,
      featureFlags,
      extractContent,
    } = this.props;

    const {
      title,
      address,
      pictures,
      minBookingDuration,
      maxBookingDuration,
      availableFrom,
      availableTo,
    } = listing;

    const {
      showingImageGallery,
      smallScreen,
      bookingFrom,
      bookingTo,
      blockedDurations,
    } = this.state;

    const description = extractContent(listing.descriptionV2);
    const titleLang = extractContent(title);
    const amenities = listing.amenities || {};
    const services = listing.services || {};
    const { coverImage } = listing;
    const { imageSrc: coverImgSrc } = getCoverImageData(listing, "large");
    const hasMultipleImages = !!pictures?.length;
    const additionalInformation = extractContent(listing.additionalInformation);
    const houseRulesText = extractContent(listing.houseRulesText);
    const petRulesConditionsText = extractContent(listing.petRules.conditions);
    const smokingRulesConditionsText = extractContent(
      listing.smokingRules.conditions,
    );

    const canEdit =
      user &&
      ((user.permissions && user.permissions.indexOf("sales") !== -1) ||
        this.isOwnListing());

    const hasAmenities =
      Object.keys(amenities)
        .map((amenity) => amenities[amenity])
        .filter((hasAmenity) =>
          typeof hasAmenity === "boolean" ? hasAmenity : false,
        ).length > 0;

    const petsAllowed = listing.petRules.rule === APARTMENT_RULES.Allowed;
    const petsAllowedWithConditions =
      listing.petRules.rule === APARTMENT_RULES.AllowedWithConditions;
    let petsText = t("petsForbidden");
    if (petsAllowed) petsText = t("petsAllowed");
    if (petsAllowedWithConditions) petsText = t("petsAllowedWithConditions");

    const smokingAllowed =
      listing.smokingRules.rule === APARTMENT_RULES.Allowed;
    const smokingAllowedWithConditions =
      listing.smokingRules.rule === APARTMENT_RULES.AllowedWithConditions;
    let smokingText = t("smokingForbidden");
    if (smokingAllowed) smokingText = t("smokingAllowed");
    if (smokingAllowedWithConditions)
      smokingText = t("smokingAllowedWithConditions");

    const validPetsRule = petsAllowedWithConditions
      ? petRulesConditionsText
      : listing.petRules.rule;
    const validSmokingRule = smokingAllowedWithConditions
      ? smokingRulesConditionsText
      : listing.smokingRules.rule;
    const hasHouseRules = houseRulesText || validPetsRule || validSmokingRule;

    const availableDate = getEarliestAvailableDate({
      availableFrom,
      blocks: blockedDurations,
      minBookingDuration,
    });

    const getHomeType = () => {
      const homeTypeText = {
        [HOME_TYPES.EntireHouse]: t(
          "spages.listing.ListingDetailsPageBase.HomeType.entireHouse",
        ),
        [HOME_TYPES.EntireApartment]: t(
          "spages.listing.ListingDetailsPageBase.HomeType.entireApartment",
        ),
        [HOME_TYPES.PrivateRoom]: t(
          "spages.listing.ListingDetailsPageBase.HomeType.privateRoom",
        ),
        [HOME_TYPES.SharedPlace]: t(
          "spages.listing.ListingDetailsPageBase.HomeType.sharedPlace",
        ),
      };

      const result = homeTypeText[listing.homeType];
      if (listing.homeType && !result && typeof window !== "undefined") {
        window?.rollbar?.error(
          `Missing translation for home type ${listing.homeType}`,
        );
      }

      return (
        result || t("spages.listing.ListingDetailsPageBase.section.subtitle")
      );
    };

    const hasRoomsInformation =
      listing.roomsInformation.map((roomInfo) =>
        Object.keys(roomInfo)
          .map((hasBeds) => listing.roomsInformation[hasBeds])
          .filter((beds) => (typeof beds === "number" ? beds > 0 : false)),
      ).length > 0;

    let mapTilerKey;
    let showMapTilerLogo = false;

    if ((listing.labels || []).includes(labels.CATEGORY_HOMES_FOR_UKRAINIANS)) {
      mapTilerKey = WF_CONFIG.MAP_TILER_UKRAINIAN_KEY;
      showMapTilerLogo = true;
    }

    const mapProps = {
      className: "ListingDetails-map",
      mapTilerKey,
    };

    const energyCertificate = listing.energyCertificate || {};

    const sanitizeVirtualTourUrl = (virtualTourUrl) => {
      // Currently we are only supporting Matterport 3D tours.
      // The virtual tours must have the following url structure:
      // https://my.matterport.com/show/?m=$tourId
      const isMatterportDomain = virtualTourUrl.includes(
        VIRTUAL_TOUR_VALID_DOMAIN,
      );
      const tourId = virtualTourUrl.slice(
        virtualTourUrl.lastIndexOf("?m=") + 3,
      );
      if (isMatterportDomain && tourId) {
        return `https://${VIRTUAL_TOUR_VALID_DOMAIN}/show/?m=${tourId}`;
      }
      return "";
    };

    const virtualTourUrl = listing?.virtualTourUrl
      ? sanitizeVirtualTourUrl(listing?.virtualTourUrl)
      : "";

    if (address) {
      const coords = address.location
        ? Array.prototype.slice.call(address.location.coordinates).reverse()
        : [0, 0];
      mapProps.scrollWheelZoom = false;
      mapProps.center = coords;
      mapProps.zoom = 16;
      mapProps.markers = [
        <ListingMarker
          t={t}
          event={(...args) => this.event(...args)}
          title={this.lvm.title(listing)}
          permalinkUrl={this.lvm.permalinkUrl(listing)}
          lang={lang}
          key={listing.groupId}
          listing={listing}
          position={coords}
          getListingTitle={(listing) => this.lvm.title(listing)}
          getListingUrl={(listing) => {
            const finalQuery = { ...query };

            return (
              this.lvm.permalinkUrl(listing) +
              (Object.keys(finalQuery).length
                ? `?${qs.stringify(finalQuery)}`
                : "")
            );
          }}
        />,
      ];
    }

    const bedNumber =
      Number(listing.totalSingleBeds) + Number(listing.totalDoubleBeds) || 1;

    const keywords = t("spages.listing.ListingDetailsPageBase.meta.keywords", {
      city: listing.address && listing.address.city,
    });
    const ogImage = listing.pictures?.[0]?.urls?.og;

    const localizedParams = {
      de: Object.assign({}, this.props.params, {
        title: slug(
          extractLanguageContent({
            langContentDictionary: listing.title,
            lang: "de",
          }),
        ),
      }),
      en: Object.assign({}, this.props.params, {
        title: slug(
          extractLanguageContent({
            langContentDictionary: listing.title,
            lang: "en",
          }),
        ),
      }),
    };

    let routeName = "listingdetails";
    if (listing.partOfGroup) {
      routeName = "multilistingdetails";
    }

    const translationUrls = Object.keys(getRoutes(routeName)).reduce(
      (acc, langKey) => {
        const nextUrl = relativeToFullyQualified(
          url(
            routeName,
            {
              ...params,
              title: slug(
                extractLanguageContent({
                  langContentDictionary: title,
                  lang: langKey,
                }),
              ),
              lang: langKey,
            },
            { query: {} },
          ),
        );
        acc[langKey] = nextUrl;
        return acc;
      },
      {},
    );

    const canonicalUrl = urlMaker(lang, { fullyQualified: true })(
      "listingdetails",
      {
        title: slug(titleLang),
        id: listing._id,
      },
    );

    const headProps = {
      title: titleLang,
      description: t(
        "spages.listing.ListingDetailsPageBase.meta.description",
        Object.assign({}, listing, {
          priceEuro: formatCurrency({
            cents: listing.price,
            currency: listing.currency,
            lang,
          }),
        }),
      ),
      keywords,
      ogImage,
      localizedParams,
      translationUrls,
      noIndex: true,
      canonicalUrl,
      structuredData: structuredData({ listing, language: lang }),
      params,
    };

    const imagesWithSections =
      // Not all listings use picturesBySection yet. Only use them for the gallery
      // if there is at least one item in picturesBySection
      listing.picturesBySection?.length > 0 &&
      listing.picturesBySection.reduce((acc, curr) => {
        acc.push(
          ...curr.images.map((image) => ({ ...image, section: curr.section })),
        );
        return acc;
      }, []);

    const isShareFeatureEnabled = isFeatureFlagEnabled(
      featureFlags,
      FEATURE_FLAGS.SHARE_OPTION,
    );

    const POPOVER_WIDTH = 335;

    const servicedApartmentCategory = categories?.find(
      ({ label }) => label === labels.CATEGORY_SERVICED_APARTMENTS,
    );

    // For Mobile Datepicker
    const today = dayjs().startOf("day");
    const minDate = dayjs(availableFrom).startOf("day").isBefore(today)
      ? today
      : dayjs(availableFrom);
    const availableToEndOfDay = availableTo
      ? dayjs(availableTo).endOf("day").subtract(1, "day")
      : null;

    const available = isPeriodAvailable({
      availableFrom,
      availableTo,
      bookingFrom,
      bookingTo,
      blockedDurations,
    });

    return (
      <div className="ListingDetails">
        <PageHead {...headProps} />
        {showingImageGallery && (
          <ImageGalleryModal
            onClose={this.onCloseGallery}
            images={
              // We use picturesBySection if available, otherwise
              // fall back to listing.pictures
              imagesWithSections || listing.pictures
            }
            coverImage={coverImage}
          />
        )}
        {this.state.canListingBeRequested.status === false && (
          <MultilistingUnavailableCard t={t} />
        )}
        <AppHeader
          border={false}
          currentUrl={this.props.currentUrl}
          lang={lang}
          t={t}
          theme={smallScreen ? "image" : "white"}
          translationUrls={translationUrls}
          user={this.props.user}
          analyticsEvent={analytics?.event}
        />
        {canEdit && (
          <span className="ListingDetails-toolbar">
            <a href={lvm.editLinkUrl(listing)}>
              {t("spages.listing.ListingDetailsPageBase.editListing")}
            </a>
          </span>
        )}
        <CoverImage
          src={coverImgSrc}
          onClick={this.showImageGallery}
          className={`ListingDetails-image${
            listing.partOfGroup ? " ListingDetails-image--multilisting" : ""
          }`}
          imageClassName="ListingDetails-imageElement"
          loading="eager"
        >
          {smallScreen && (
            <React.Fragment>
              <div className="ListingDetails-mobileButtonGroup">
                <Button
                  size="medium"
                  onClick={(e) => {
                    e.stopPropagation();
                    this.showImageGallery();
                  }}
                  className="ListingDetails-viewGalleryButton"
                  colorVariant="white"
                >
                  {t("spages.listing.ListingDetailsPageBase.viewGallery")}
                </Button>
                {listing.floorPlanUrl && (
                  <Link
                    target="_blank"
                    href={listing.floorPlanUrl}
                    className="ListingDetails-floorPlanButton--noLabel"
                    onClick={(e) => e.stopPropagation()}
                    size="medium"
                    colorVariant="white"
                  >
                    <FloorPlan />
                  </Link>
                )}
              </div>
              {!canEdit && canUseDOM() && (
                <ShortlistButton
                  eventLabel="Listing Detail Page"
                  className="ListingDetails-shortlistButton"
                  withToaster
                  groupId={listing.groupId}
                  t={t}
                  url={url}
                />
              )}
              {isShareFeatureEnabled && (
                <ShareButton
                  title={t("sharingPopoverTitle")}
                  subject={`Wunderflats - ${titleLang}`}
                  text={t("sharingMessage")}
                  url={this.props.currentUrl}
                  label={t("share")}
                  buttonClassName="ListingDetails-shareButton"
                  isSmallScreen={smallScreen}
                  shouldStopPropagation
                  analyticsEvent={this.event}
                />
              )}
            </React.Fragment>
          )}
          {listing.partOfGroup && !listing._id && (
            <div className="Multilisting-multilistingBadge">
              <MultilistingBadge
                t={t}
                numApartments={listing.group.length}
                label={t(
                  "spages.multilisting.MultilistingDetailsPage.disclaimer",
                  {
                    count: listing.group.length,
                  },
                )}
                secondaryText={t(
                  "spages.multilisting.MultilistingDetailsPage.imageDisclaimer",
                )}
              />
            </div>
          )}
          {isServicedApartment(listing) && (
            <ServicedApartmentBadge
              className={classnames({
                BelowMultilistingBadge: listing.partOfGroup && !listing._id,
              })}
              popoverWidth={POPOVER_WIDTH}
              city={listing.address?.city}
              query={query}
              slug={servicedApartmentCategory?.slug}
            />
          )}
          {hasMultipleImages && !smallScreen && (
            <div>
              <div className="ImageGallery-navButton ImageGallery-navButton--left">
                <img
                  src="/public/images/gallery-nav-prev-icon.svg"
                  alt="Previous"
                />
              </div>
              <div className="ImageGallery-navButton ImageGallery-navButton--right">
                <img
                  src="/public/images/gallery-nav-next-icon.svg"
                  alt="Next"
                />
              </div>
            </div>
          )}
        </CoverImage>

        <div className="ListingDetails-wrapper">
          <article className="ListingDetails-content">
            <header>
              <div className="ListingDetails-desktopButtons">
                <Button
                  size="medium"
                  colorVariant="white"
                  className="ListingDetails-viewGalleryButton"
                  onClick={this.showImageGallery}
                >
                  <Gallery />
                  <span className="ListingDetails-viewGalleryText">
                    {t("spages.listing.ListingDetailsPageBase.viewGallery")}
                  </span>
                </Button>

                {listing.floorPlanUrl && (
                  <Link
                    size="medium"
                    colorVariant="white"
                    className="ListingDetails-floorPlanButton"
                    href={listing.floorPlanUrl}
                    target="_blank"
                  >
                    <FloorPlan />
                    <span className="ListingDetails-floorPlanText">
                      {t("floorPlan")}
                    </span>
                  </Link>
                )}
                {!canEdit && canUseDOM() && (
                  <ShortlistButton
                    eventLabel="Listing Detail Page"
                    className="ListingDetails-shortlistButtonDesktop"
                    hasLabel
                    groupId={listing.groupId}
                    url={url}
                    t={t}
                  />
                )}
                {isShareFeatureEnabled && (
                  <ShareButton
                    title={t("sharingPopoverTitle")}
                    subject={`Wunderflats - ${titleLang}`}
                    text={t("sharingMessage")}
                    url={this.props.currentUrl}
                    label={t("share")}
                    buttonClassName="ListingDetails-shareButton"
                    analyticsEvent={this.event}
                  />
                )}
              </div>
              {(listing.isLandlordIdVerified ||
                listing.restrictions.onlyStudentsAllowed) && (
                <div className="ListingDetails-indicatorWrapper">
                  {listing.isLandlordIdVerified && (
                    <div
                      className="ListingDetails-landlordVerified"
                      data-testid="ListingDetailsPage-landlordVerifiedIndicator"
                    >
                      <CircularCheckmark className="ListingDetails-landlordVerified-image" />
                      <span>{t("landlordVerified")}</span>
                    </div>
                  )}
                  {listing.restrictions.onlyStudentsAllowed && (
                    <div
                      className="ListingDetails-studentOnly"
                      data-testid="ListingDetailsPage-studentOnly"
                    >
                      <StudentCap className="ListingDetails-studentOnly-image" />
                      <span>{t("exclusivelyForStudents")}</span>
                    </div>
                  )}
                </div>
              )}
              <h2 className="ListingDetails-title heading-large heading-large--bold qa-listing-title">
                {titleLang}
              </h2>
              {listing.address && (
                <div className="ListingDetails-basic">
                  <LocationMarker />
                  <span
                    className="txt-light"
                    data-testid="ListingDetailsPage-address"
                  >
                    {getApartmentAddress(listing)}
                  </span>
                </div>
              )}
              <div className="ListingDetails-stats">
                <span className="ListingDetails-statsElt floor">
                  <span className="ListingDetails-statsText">
                    <span>
                      {`${listing.area} m`}
                      <sup>2</sup>
                      {listing.floor === 0 && <span>,&nbsp;</span>}
                    </span>
                    {listing.floor === 0 && (
                      <span className="groundFloor">{t("groundFloor")}</span>
                    )}
                    {!!listing.floor && listing.floor > 0 && (
                      <span>
                        , {t("floor")}: {listing.floor}
                      </span>
                    )}
                  </span>
                </span>
                <span className="ListingDetails-statsElt rooms">
                  <span className="ListingDetails-statsText">
                    {t("spages.listing.ListingDetailsPageBase.stats.rooms", {
                      rooms: Number(listing.roomsInformation?.length) || 1,
                    })}
                  </span>
                </span>

                {listing.amenities.wifi &&
                listing.services.internetConnectionSpeedMbps ? (
                  <span
                    className="ListingDetails-statsElt wifi"
                    data-testid="ListingDetailsPage-wifi"
                  >
                    <span className="ListingDetails-statsText">
                      {t("internetSpeed", {
                        speed: listing.services.internetConnectionSpeedMbps,
                      })}
                    </span>
                  </span>
                ) : (
                  <span
                    className="ListingDetails-statsElt beds"
                    data-testid="ListingDetailsPage-beds"
                  >
                    <span className="ListingDetails-statsText">
                      {t("spages.listing.ListingDetailsPageBase.stats.beds", {
                        beds: bedNumber,
                      })}
                    </span>
                  </span>
                )}
                <span className="ListingDetails-statsElt guests">
                  <span className="ListingDetails-statsText">
                    {t("spages.listing.ListingDetailsPageBase.stats.guests", {
                      guests: Number(listing.accommodates) || 1,
                    })}
                  </span>
                </span>
              </div>
            </header>

            <ViennaStayDurationMessage
              t={t}
              country={listing.address?.country}
              zipCode={listing.address?.zipCode}
              maxBookingDuration={
                listing.maxBookingDuration || MAX_BOOKING_DURATION.Vienna
              }
              cookies={cookies}
            />
            <section id="aboutListing" className="ListingDetails-about">
              <div>
                <div className="ListingDetails-about-title">
                  <h3>
                    {t("spages.listing.ListingDetailsPageBase.section.about")}
                  </h3>
                  {listing.verified?.visit && (
                    <VerifiedBadge t={t} smallScreen={this.state.smallScreen} />
                  )}
                </div>
                <h4 className="ListingDetails-about-subtitle">
                  {getHomeType()}
                </h4>
                <p>
                  {this.getReadMore(
                    description,
                    "descriptionExpanded",
                    "aboutListing",
                  )}
                </p>
              </div>

              {additionalInformation && (
                <div
                  id="additionalInfo"
                  className="ListingDetails-additional-info"
                >
                  <h4>
                    {t(
                      "spages.listing.ListingDetailsPageBase.section.additionalInformation",
                    )}
                  </h4>
                  <p>
                    {this.getReadMore(
                      additionalInformation,
                      "additionalInfoExpanded",
                      "additionalInfo",
                    )}
                  </p>
                </div>
              )}
            </section>

            {virtualTourUrl ? (
              <section className="ListingDetails-virtualTour">
                <h3>
                  {t("spages.listing.ListingDetailsPageBase.virtualTour")}
                </h3>
                <div className="matterport-showcase">
                  <iframe
                    title={titleLang}
                    src={virtualTourUrl}
                    frameBorder="0"
                    allowFullScreen
                    allow="xr-spatial-tracking"
                  />
                </div>
              </section>
            ) : null}

            <section className="ListingDetails-services">
              <h3>
                {t("spages.listing.ListingDetailsPageBase.section.services")}
              </h3>
              <ServicesList t={t} services={services} labels={listing.labels} />
            </section>

            {hasAmenities && (
              <section className="ListingDetails-amenities">
                <h3>
                  {t("spages.listing.ListingDetailsPageBase.section.amenities")}
                </h3>
                <AmenitiesList t={t} amenities={amenities} />
              </section>
            )}

            {hasRoomsInformation && (
              <section className="ListingDetails-roomsInformation">
                <h3>{t("beds")}</h3>
                <BedsList t={t} roomsInformation={listing.roomsInformation} />
              </section>
            )}

            <section
              className={`ListingDetails-calendar${
                listing.partOfGroup
                  ? ` ListingDetails-MultilistingBookingFormContainer`
                  : ""
              }`}
            >
              <h3>
                {t(
                  "spages.listing.ListingDetailsPageBase.section.availability",
                )}
              </h3>
              <div className="ListingDetails-availability">
                {listing.minBookingDuration && (
                  <div
                    className="ListingDetails-availability_duration"
                    data-testid="ListingDetailsPage-minBookingDuration"
                  >
                    <div className="statsWrapper">
                      <div className="statsLabel">{t("minimumStay")}</div>
                      <div className="statsText">
                        {t("iMonths", { num: listing.minBookingDuration || 1 })}
                      </div>
                    </div>
                  </div>
                )}

                {listing.maxBookingDuration && (
                  <div
                    className="ListingDetails-availability_duration"
                    data-testid="ListingDetailsPage-maxBookingDuration"
                  >
                    <div className="statsWrapper">
                      <div className="statsLabel">{t("maximumStay")}</div>
                      <div className="statsText">
                        {t("iMonths", { num: listing.maxBookingDuration })}
                      </div>
                    </div>
                  </div>
                )}

                {!listing.partOfGroup && availableDate && (
                  <div
                    className="ListingDetails-availability_date"
                    data-testid="ListingDetailsPage-availabilityDate"
                  >
                    <div className="statsWrapper">
                      <div className="statsLabel">{t("availableFrom")}</div>
                      <div className="statsText">{availableDate}</div>
                    </div>
                  </div>
                )}
              </div>
              {listing.partOfGroup && !listing._id
                ? this.renderDateRequestForm()
                : this.renderCalendar()}
            </section>

            <section className="no-touch">
              <ComponentLazyLoad>
                <Map {...mapProps}>{showMapTilerLogo && <MapTilerLogo />}</Map>
              </ComponentLazyLoad>
            </section>

            {hasHouseRules && (
              <section className="ListingDetails-houseRules" id="houseRules">
                <h3>{t("houseRules")}</h3>
                {houseRulesText &&
                  this.getReadMore(
                    houseRulesText,
                    "houseRulesExpanded",
                    "houseRules",
                  )}
                <div className="ListingDetails-houseRulesContainer">
                  {validPetsRule && (
                    <div className="ListingDetails-houseRule">
                      <span className="ListingDetails-houseRule-icon">
                        {petsAllowed || petsAllowedWithConditions ? (
                          <PetsAllowed />
                        ) : (
                          <PetsForbidden />
                        )}
                      </span>
                      <span className="ListingDetails-houseRule-text">
                        <h4 className="ListingDetails-houseRule-heading">
                          {petsText}
                        </h4>
                        {petsAllowedWithConditions &&
                          petRulesConditionsText && (
                            <p className="ListingDetails-houseRule-conditionText">
                              {petRulesConditionsText}
                            </p>
                          )}
                      </span>
                    </div>
                  )}
                  {validSmokingRule && (
                    <div className="ListingDetails-houseRule">
                      <span className="ListingDetails-houseRule-icon">
                        {smokingAllowed || smokingAllowedWithConditions ? (
                          <SmokingAllowed />
                        ) : (
                          <SmokingForbidden />
                        )}
                      </span>
                      <span className="ListingDetails-houseRule-text">
                        <h4 className="ListingDetails-houseRule-heading">
                          {smokingText}
                        </h4>
                        {smokingAllowedWithConditions &&
                          smokingRulesConditionsText && (
                            <p className="ListingDetails-houseRule-conditionText">
                              {smokingRulesConditionsText}
                            </p>
                          )}
                      </span>
                    </div>
                  )}
                </div>
              </section>
            )}

            <section className="ListingDetails-costOverview">
              <h3>
                {t(
                  "spages.listing.ListingDetailsPageBase.section.costOverview",
                )}
              </h3>
              <CostOverview
                t={t}
                listing={listing}
                lang={lang}
                tracker={this.props.tracker}
              />
            </section>

            <section className="ListingDetails-certifications">
              <h3>
                {t("spages.listing.ListingDetailsPageBase.certifications")}
              </h3>
              <div className="ListingDetails-certificationsContainer">
                {energyCertificate.availability && (
                  <div>
                    <h4>
                      {t(
                        "spages.listing.ListingDetailsPageBase.EnergyCertificate.title",
                      )}
                    </h4>
                    {energyCertificate.availability ===
                      energyCertificates.AVAILABILITY.AvailableForViewing && (
                      <p>
                        {t(
                          "spages.listing.ListingDetailsPageBase.EnergyCertificate.availability.AVAILABLE_FOR_VIEWING",
                        )}
                      </p>
                    )}

                    {energyCertificate.availability ===
                      energyCertificates.AVAILABILITY.NotNeeded && (
                      <p>
                        {t(
                          "spages.listing.ListingDetailsPageBase.EnergyCertificate.availability.NOT_NEEDED",
                        )}
                      </p>
                    )}

                    {energyCertificate.availability ===
                      energyCertificates.AVAILABILITY.Available && (
                      <ul>
                        {energyCertificate.energyEfficiencyClass && (
                          <li>
                            {t(
                              "spages.listing.ListingDetailsPageBase.EnergyCertificate.energyEfficiencyClass",
                            )}
                            : {energyCertificate.energyEfficiencyClass}
                          </li>
                        )}

                        <li>
                          {t(
                            "spages.listing.ListingDetailsPageBase.EnergyCertificate.certificateType.label",
                          )}
                          :{" "}
                          {t(
                            `spages.listing.ListingDetailsPageBase.EnergyCertificate.certificateType.${energyCertificate.certificateType}`,
                          )}
                        </li>

                        <li>
                          {t(
                            "spages.listing.ListingDetailsPageBase.EnergyCertificate.certificateIssueDate.label",
                          )}
                          :{" "}
                          {t(
                            `spages.listing.ListingDetailsPageBase.EnergyCertificate.certificateIssueDate.${energyCertificate.certificateIssueDate}`,
                          )}
                        </li>

                        <li>
                          {t(
                            "spages.listing.ListingDetailsPageBase.EnergyCertificate.mainEnergySource.label",
                          )}
                          :{" "}
                          {t(
                            `spages.listing.ListingDetailsPageBase.EnergyCertificate.mainEnergySource.${energyCertificate.mainEnergySource}`,
                          )}
                        </li>

                        <li>
                          {t(
                            "spages.listing.ListingDetailsPageBase.EnergyCertificate.heatingMethod.label",
                          )}
                          :{" "}
                          {t(
                            `spages.listing.ListingDetailsPageBase.EnergyCertificate.heatingMethod.${energyCertificate.heatingMethod}`,
                          )}
                        </li>

                        {!!energyCertificate.yearOfConstruction && (
                          <li>
                            {t("yearOfConstruction")}:{" "}
                            {energyCertificate.yearOfConstruction}
                          </li>
                        )}

                        {!!energyCertificate.finalEnergyConsumptionIdentityValue &&
                          energyCertificate.certificateType ===
                            energyCertificates.CERTIFICATE_TYPE
                              .RequirementPass && (
                            <li>
                              {t(
                                "spages.listing.ListingDetailsPageBase.EnergyCertificate.finalEnergyConsumptionIdentityValue",
                              )}
                              :{" "}
                              {
                                energyCertificate.finalEnergyConsumptionIdentityValue
                              }{" "}
                              kWh/(m²*a)
                            </li>
                          )}

                        {typeof energyCertificate.energyConsumptionIncludesWarmWater ===
                          "boolean" &&
                          energyCertificate.certificateIssueDate ===
                            energyCertificates.CERTIFICATE_ISSUE_DATE
                              .BeforeApril2014 &&
                          energyCertificate.certificateType ===
                            energyCertificates.CERTIFICATE_TYPE
                              .ConsumptionPass && (
                            <li>
                              {t(
                                "spages.listing.ListingDetailsPageBase.EnergyCertificate.energyConsumptionIncludesWarmWaterLabel",
                              )}
                              :{" "}
                              {energyCertificate.energyConsumptionIncludesWarmWater
                                ? t("yes")
                                : t("no")}
                            </li>
                          )}
                      </ul>
                    )}
                  </div>
                )}

                <RegistrationNumber
                  listing={listing}
                  smallScreen={this.state.smallScreen}
                />

                {(listing.minBookingDurationExemption ||
                  listing.minBookingDurationPermitId) && (
                  <PermitIdComponent
                    listing={listing}
                    smallScreen={this.state.smallScreen}
                  />
                )}
              </div>
            </section>

            {this.state.smallScreen && !showingImageGallery && (
              <MobileCallOut
                t={t}
                lang={lang}
                from={{
                  onChange: this.onSetBookingFrom,
                  value: bookingFrom,
                }}
                to={{
                  onChange: this.onSetBookingTo,
                  value: bookingTo,
                }}
                ref={this.dateRangePickerRef}
                minBookingDuration={minBookingDuration || 1}
                maxBookingDuration={maxBookingDuration}
                minDate={minDate}
                maxDate={availableToEndOfDay}
                blockedDurations={blockedDurations}
                available={available}
                onClick={this.onClickMobileCallOut}
                listingPrice={listing.price}
                currency={listing.currency}
                loading={
                  this.state.datesRequestLoading ||
                  this.state.canListingBeRequested.requesting
                }
                datesAvailable={this.state.datesAvailable}
                multilisting={listing.partOfGroup}
                canListingBeRequested={this.state.canListingBeRequested}
                isTenantServiceFeeVisible={this.showTenantServiceFeeOnRequestToBookSection()}
              />
            )}
          </article>
          <aside
            className={`ListingDetails-bookingSidebar ${this.state.sidebarClassName}`}
            ref={this.bookingSalesRef}
          >
            {listing.partOfGroup
              ? this.renderStartCreateBookingWizardFormMultilisting()
              : this.renderStartCreateBookingWizardFormSingleListing()}
            <div className="bookingTeam">
              <img src={BOOKING_TEAM_IMAGE_SRC} />
              <div className="bookingTeam-title">
                {t("spages.listing.ListingDetailsPageBase.salesContact.title")}
              </div>
              <p className="bookingTeam-info">
                {t("spages.listing.ListingDetailsPageBase.salesContact.info")}
              </p>

              {smallScreen && (
                <div className="contactInfo">
                  <button
                    onClick={this.showFreshdeskWidget}
                    className="btn contactButton"
                  >
                    {t(
                      "spages.listing.ListingDetailsPageBase.salesContact.button",
                    )}
                  </button>
                </div>
              )}
            </div>
          </aside>
        </div>

        {this.listingIdForSimilarListings && (
          <ComponentLazyLoad>
            <SimilarListings
              api={this.props.api}
              city={listing.address?.city}
              listingId={this.listingIdForSimilarListings}
              query={this.props.query}
              similarToGroupId={listing.groupId}
              title={t("spages.listing.ListingDetailsPageBase.section.similar")}
              url={url}
              trackComponentLocation="ListingDetailsPage"
            />
          </ComponentLazyLoad>
        )}
        <Footer url={url} user={user} />

        <FreshdeskHelpWidget
          isButtonVisible={
            smallScreen ? false : this.state.isContactUsWidgetVisible
          }
        />
      </div>
    );
  }
}

export default withTracker(withI18n(ListingsDetailsPage));
ListingsDetailsPage.contextType = FeatureFlagContext;
