import React from "react";

const LocationMarker = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 8.47826C5 11.4 8.54167 15.3768 10 17C11.4583 15.3768 15 11.4 15 8.47826C15 4.82609 12.5 3 10 3C7.5 3 5 4.82609 5 8.47826Z"
        fill="#575D64"
        stroke="#575D64"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="10" cy="8" r="2" fill="#F7F9FA" />
    </svg>
  );
};

export default LocationMarker;
