import React from "react";

const Twitter = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#1DA1F2"
      d="M22 6.028a7.861 7.861 0 01-2.356.682 4.314 4.314 0 001.804-2.394 8.015 8.015 0 01-2.607 1.052C18.092 4.525 17.027 4 15.846 4c-2.266 0-4.103 1.939-4.103 4.328 0 .34.036.67.106.987C8.44 9.134 5.417 7.41 3.393 4.79a4.48 4.48 0 00-.554 2.176c0 1.502.724 2.828 1.824 3.603a3.941 3.941 0 01-1.858-.543v.054c0 2.097 1.415 3.847 3.29 4.245a3.951 3.951 0 01-1.08.152c-.266 0-.522-.028-.773-.08.522 1.72 2.037 2.972 3.832 3.006A7.962 7.962 0 012 19.195a11.162 11.162 0 006.289 1.948c7.547 0 11.673-6.597 11.673-12.318l-.014-.56A8.456 8.456 0 0022 6.028z"
    />
  </svg>
);

export default Twitter;
