import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  className: PropTypes.string,
};

const StudentCap = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      className={className}
    >
      <circle fill="#f5f1fb" cx="18" cy="18" r="18" />
      <path
        fill="#ede8f7"
        d="M25.26,14.54H11.36v6.51c0,1.54,3.11,3.77,7,3.77s7-2.23,7-3.77V14.54Z"
      />
      <path
        fill="#fff"
        d="M28.64,13.52,19.5,10a3.36,3.36,0,0,0-2.38,0L8,13.52a.55.55,0,0,0,0,1l3.69,1.74v2.41c0,1.54,3,3.77,6.68,3.77S25,20.23,25,18.69V16.28l3.69-1.74A.55.55,0,0,0,28.64,13.52Z"
      />
      <path
        fill="#070707"
        d="M28.9,12.81,19.76,9.33a4.06,4.06,0,0,0-2.91,0L7.71,12.81a1.31,1.31,0,0,0-.09,2.41l.47.22h0v5.91a1.1,1.1,0,0,0-.67,1,1.08,1.08,0,0,0,.47.88L7,26.19h3.19l-.94-3a1.08,1.08,0,0,0,.41-.83,1.12,1.12,0,0,0-.56-.95V15.91l1.51.71v4.43c0,2,3.43,4.51,7.7,4.51S26,23.09,26,21.05V16.62l3-1.4A1.31,1.31,0,0,0,29.74,14,1.28,1.28,0,0,0,28.9,12.81Zm-4.39,8.24c0,.87-2.47,3-6.2,3s-6.2-2.14-6.2-3v-.81a8.66,8.66,0,0,0,6.2,2.46,8.67,8.67,0,0,0,6.2-2.47Zm.22-2.36a1.39,1.39,0,0,1-.22.71c-.71,1.3-3.22,2.8-6.2,2.8s-5.47-1.48-6.2-2.78a1.44,1.44,0,0,1-.23-.73V17.22l.23.11,4.46,2.09a4.05,4.05,0,0,0,1.74.39,4.12,4.12,0,0,0,1.74-.39l4.46-2.1.22-.1ZM26,15l-.78.36-.5.24-.22.1-5.1,2.4a2.57,2.57,0,0,1-2.2,0l-5.1-2.39-.23-.11-.5-.24L10.61,15l-1.94-.91,2.71-1,.5-.19,1-.4,1.31-.5,3.15-1.2a2.57,2.57,0,0,1,1.85,0l3.15,1.2,1.31.5,1,.4.5.19,2.71,1Z"
      />
      <path opacity="0.15" d="M18,19.68,22.25,24s3-1.34,3-2.39V16.15Z" />
    </svg>
  );
};

StudentCap.propTypes = propTypes;

export default StudentCap;
