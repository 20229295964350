import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import StatusCard from "../../../../../components/StatusCard/StatusCard";

const propTypes = {
  t: PropTypes.func.isRequired,
};

const MultilistingUnavailableCard = ({ t }) => {
  const [hiding, setHiding] = React.useState(false);

  return (
    <StatusCard
      className={classnames("MultilistingUnavailableCard", {
        "MultilistingUnavailableCard--hide": hiding,
      })}
      intent="danger"
    >
      <div className="MultilistingUnavailableCard-text">
        {t(
          "spages.multilisting.MultilistingDetailsPage.MultilistingUnavailableCard.text",
        )}
      </div>
      <button
        className="MultilistingUnavailableCard-close"
        onClick={() => setHiding(true)}
      >
        <svg
          width="30"
          height="30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="15" cy="15" r="14.5" stroke="#E3E9F0" />
          <path
            transform="rotate(135 19.73 11.322)"
            fill="url(#paint0_linear)"
            d="M19.73 11.322h12v1.6h-12z"
          />
          <path
            transform="rotate(45 11.248 10.191)"
            fill="url(#paint1_linear)"
            d="M11.248 10.191h12v1.6h-12z"
          />
          <defs>
            <linearGradient
              id="paint0_linear"
              x1="19.997"
              y1="12.358"
              x2="21.976"
              y2="7.782"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset=".199" stopColor="#8457DF" />
              <stop offset=".992" stopColor="#A03FC5" />
            </linearGradient>
            <linearGradient
              id="paint1_linear"
              x1="11.514"
              y1="11.227"
              x2="13.493"
              y2="6.651"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset=".199" stopColor="#8457DF" />
              <stop offset=".992" stopColor="#A03FC5" />
            </linearGradient>
          </defs>
        </svg>
      </button>
    </StatusCard>
  );
};

MultilistingUnavailableCard.propTypes = propTypes;
export default MultilistingUnavailableCard;
