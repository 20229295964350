import React from "react";
import PropTypes from "prop-types";
import ImageGallery from "../../../../../components/ImageGallery/ImageGallery";
import Modal from "../../../../../components/Modal/Modal";

ImageGalleryModal.propTypes = {
  images: PropTypes.array,
  onClose: PropTypes.func,
  coverImage: PropTypes.object,
};

function ImageGalleryModal({ onClose, images, coverImage }) {
  return (
    <Modal onClose={onClose} className="ImageGalleryModal" roundCloseButton>
      <ImageGallery images={images} cover={false} coverImage={coverImage} />
    </Modal>
  );
}

export default ImageGalleryModal;
