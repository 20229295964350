const getCoverImageData = ({ coverImage, pictures }, imageSize) => {
  if (!imageSize) {
    throw new Error("No cover image size specified");
  }
  const coverImageUrls =
    typeof coverImage === "string"
      ? pictures?.find((picture) => picture.imageId === coverImage)?.urls
      : coverImage?.urls;
  const imageUrls = coverImageUrls || pictures?.[0]?.urls;

  return {
    imageUrls,
    imageSrc: imageUrls?.[imageSize] || "/public/images/listing-bg.jpg",
  };
};

export default getCoverImageData;
