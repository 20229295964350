import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { useDevice } from "../../spages/spa/context/DeviceContext";
import { Button } from "../DesignSystem/Button/Button";
import Share from "../Icons/Share";
import SharingPopover from "./SharingPopover";

import "./ShareButton.scss";

const ShareButton = ({
  title,
  text,
  url,
  token,
  label,
  popoverPosition,
  subject,
  buttonClassName,
  isSmallScreen,
  shouldStopPropagation,
  analyticsEvent,
}) => {
  const [show, setShow] = useState(false);
  const { isAPhone } = useDevice();

  const onClose = () => {
    setShow(false);
  };
  const onClickHandler = (e) => {
    if (shouldStopPropagation) {
      e.stopPropagation();
    }

    if (navigator && navigator.share) {
      navigator.share({ title, url, text });
    } else {
      setShow(!show);
    }
  };

  const around = (
    <Button
      className={classnames(buttonClassName, "ShareButton")}
      onClick={onClickHandler}
      colorVariant="white"
      size="medium"
      data-testid="ShareButton"
    >
      <Share className="ShareButton-icon" />
      <span className="ShareButton-label">{label}</span>
    </Button>
  );

  return (
    <SharingPopover
      isVisible={show}
      isDrawer={isAPhone || isSmallScreen}
      onClose={onClose}
      around={around}
      title={title}
      position={popoverPosition}
      text={text}
      url={url}
      token={token}
      subject={subject}
      analyticsEvent={analyticsEvent}
    />
  );
};

ShareButton.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  token: PropTypes.string,
  label: PropTypes.string,
  popoverPosition: PropTypes.string,
  subject: PropTypes.string,
  buttonClassName: PropTypes.string,
  isSmallScreen: PropTypes.bool,
  shouldStopPropagation: PropTypes.bool,
  analyticsEvent: PropTypes.func,
};

export default ShareButton;
