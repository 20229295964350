import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const PetsAllowed = ({ width = 30, height = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={width}
      height={height}
    >
      <path
        fill="#f5f1fb"
        d="M15,0h0A15,15,0,0,1,30,15h0A15,15,0,0,1,15,30h0A15,15,0,0,1,0,15H0A15,15,0,0,1,15,0Z"
      />
      <rect
        fill="#e9ebf2"
        opacity="0"
        x="5.35"
        y="6"
        width={width}
        height={height}
      />
      <path
        fill="#fff"
        d="M21.56,14.23l-1.78-1.17a4.17,4.17,0,0,0-4.05-3.72l-.28-2.46c-.16-.6-.94-.66-1.29.06a24.69,24.69,0,0,0-2.36,5.17c-.05.16-.45,1.75-.94,3.28a15.26,15.26,0,0,1-1,2.62c-.94,1.39-1.4,1.79-1.55,3.12a1,1,0,0,0,.92,1.07l10.54.21c.45,0-1.75-3.91-1.87-4.88,0-.37.26-.39.26-.39a3.84,3.84,0,0,0,1.79-.21c1.48-.49,1.92-1.23,2-1.77A.93.93,0,0,0,21.56,14.23Z"
      />
      <path
        fill="#732dd9"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5px"
        d="M14.27,18.07a7.84,7.84,0,0,1-3.57-1.89,10.88,10.88,0,0,1-.62,1.49,9.85,9.85,0,0,0,3.81,1.87,9.64,9.64,0,0,0,4.73.09c-.22-.49-.44-1-.61-1.4A8.33,8.33,0,0,1,14.27,18.07Z"
      />
      <path d="M21.7,14,20,12.91A4.45,4.45,0,0,0,16,9.1l-.2-2.26A1.07,1.07,0,0,0,14.9,6a1.3,1.3,0,0,0-1.26.77A25,25,0,0,0,11.3,12c-.14.53-.5,1.91-.92,3.24a14.21,14.21,0,0,1-1,2.49l-.48.69a5,5,0,0,0-1.15,2.65,1.51,1.51,0,0,0,.37,1.16,1.39,1.39,0,0,0,1.05.47H19.62a.46.46,0,0,0,.39-.18c.16-.25,0-.74-.87-2.55a14.51,14.51,0,0,1-1-2.48c0-.09,0-.1,0-.1A4.23,4.23,0,0,0,20,17.17c1.59-.53,2.07-1.36,2.19-2A1.15,1.15,0,0,0,21.7,14Zm-3.43,5.44A9.66,9.66,0,0,1,14,19.3a9.8,9.8,0,0,1-3.4-1.6.91.91,0,0,1-.16-.11,8.87,8.87,0,0,0,.41-1l.13.11a8.57,8.57,0,0,0,3.28,1.57,8.49,8.49,0,0,0,3.64.21Zm1.1,2.24H9.21a.39.39,0,0,1-.3-.14.49.49,0,0,1-.13-.38,4,4,0,0,1,1-2.19c.14-.21.31-.44.5-.73l.06-.1a10.2,10.2,0,0,0,3.51,1.62,11.14,11.14,0,0,0,2.71.35,8.8,8.8,0,0,0,2-.23l.13.28C18.91,20.66,19.18,21.25,19.37,21.7Zm.48-5a3.87,3.87,0,0,1-1.73.2.52.52,0,0,0-.37.19.62.62,0,0,0-.11.48,1.69,1.69,0,0,0,.12.45,8.14,8.14,0,0,1-3.43-.19,7.92,7.92,0,0,1-3.22-1.6l.22-.68c.43-1.35.8-2.75.95-3.31a25.48,25.48,0,0,1,2.24-5A.43.43,0,0,1,14.8,7c.16,0,.09.4.09.4-.05.23-.15.68-.31,1.33s-.41,1.8-.41,1.8a2.06,2.06,0,0,1,1.41-.93h.15a3.94,3.94,0,0,1,3.8,3.5v.12l.94.61h0c-.25.15-.71.48-.32.79a1.4,1.4,0,0,1,.27,1.85A4.67,4.67,0,0,1,19.85,16.69Z" />
    </svg>
  );
};

PetsAllowed.propTypes = propTypes;

export default PetsAllowed;
