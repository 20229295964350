import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button } from "../../../../components/DesignSystem/Button/Button";
import { IconButton } from "../../../../components/DesignSystem/IconButton/IconButton";
import CloseIcon from "../../../../components/Icons/Close";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import Popover, { placements } from "../../../../components/Popover/Popover";
import { useI18n } from "../../context/I18nContext";

import "./PermitIdComponent.scss";

const PermitIdComponent = ({ listing, smallScreen = false }) => {
  const { t } = useI18n();
  const [isPopoverForTitleOpen, setIsPopoverForTitleOpen] = useState(false);
  const [isPopoverForBodyOpen, setIsPopoverForBodyOpen] = useState(false);

  const renderTitle = () => {
    return (
      <Popover
        showInDrawer={smallScreen}
        show={isPopoverForTitleOpen}
        onClose={() => setIsPopoverForTitleOpen(false)}
        placement={placements.right}
        preventScroll
        around={
          <button
            type="button"
            className="PermitIdComponentTitlePopover-toggleButton"
            onClick={() => setIsPopoverForTitleOpen(!isPopoverForTitleOpen)}
          >
            <h4>{t("spages.listing.ListingDetailsPageBase.permitId.title")}</h4>
            <InfoIcon />
          </button>
        }
      >
        {!smallScreen && (
          <IconButton
            size="small"
            className="PermitIdComponentPopover-closeButton"
            onClick={() => setIsPopoverForTitleOpen(false)}
            icon={<CloseIcon width={18} height={18} />}
          />
        )}

        <div className="PermitIdComponentPopover-content">
          <h3 className="PermitIdComponentPopover-contentTitle">
            {t(
              "spages.listing.ListingDetailsPageBase.permitId.titlePopover.contentTitle",
            )}
          </h3>
          <p className="PermitIdComponentPopover-contentInfo">
            {t(
              "spages.listing.ListingDetailsPageBase.permitId.titlePopover.contentInfo",
            )}
          </p>
          <Button
            onClick={() => setIsPopoverForTitleOpen(false)}
            colorVariant="secondary"
            widthVariant="fullWidth"
          >
            {t(
              "spages.listing.ListingDetailsPageBase.permitId.popover.gotItButton",
            )}
          </Button>
        </div>
      </Popover>
    );
  };

  const landlordImprintLink = (
    <a
      className="PermitIdComponentBodyPopover-moreInformationLink MoreInformationLink"
      data-testid="PermitIdComponentImprintFile"
      href={`/api/file/download/${listing.minBookingDurationImprintFileId}`}
      target="_blank"
      rel="noreferrer"
    >
      {t("spages.listing.ListingDetailsPageBase.permitId.moreInformation")}
    </a>
  );

  const renderBody = () => {
    if (listing.minBookingDurationExemption) {
      return (
        <React.Fragment>
          <p
            data-testid="PermitIdComponentExemption"
            data-hasPermitIdComponent={Boolean(
              listing.minBookingDurationPermitId,
            )}
          >
            {t("spages.listing.ListingDetailsPageBase.permitId.exempt")}
          </p>
          {listing.minBookingDurationImprintFileId && landlordImprintLink}
        </React.Fragment>
      );
    }

    if (!listing.partOfGroup) {
      return (
        <span
          data-testid="PermitIdComponentForSingleListing"
          data-hasPermitIdComponent={Boolean(
            listing.minBookingDurationPermitId,
          )}
        >
          {listing.minBookingDurationPermitId}
          {listing.minBookingDurationImprintFileId && landlordImprintLink}
        </span>
      );
    }

    return (
      <Popover
        showInDrawer={smallScreen}
        show={isPopoverForBodyOpen}
        onClose={() => setIsPopoverForBodyOpen(false)}
        placement={placements.right}
        preventScroll
        around={
          <button
            type="button"
            data-testid="PermitIdComponentToggleForMultiListing"
            className="PermitIdComponentBodyPopover-toggleButton"
            onClick={() => setIsPopoverForBodyOpen(!isPopoverForBodyOpen)}
          >
            {t(
              "spages.listing.ListingDetailsPageBase.permitId.bodyPopover.toggleButton",
            )}
          </button>
        }
      >
        {!smallScreen && (
          <IconButton
            size="small"
            className="PermitIdComponentPopover-closeButton"
            onClick={() => setIsPopoverForBodyOpen(false)}
            icon={<CloseIcon width={18} height={18} />}
          />
        )}

        <div className="PermitIdComponentPopover-content">
          <h3 className="PermitIdComponentPopover-contentTitle">
            {t(
              "spages.listing.ListingDetailsPageBase.permitId.bodyPopover.contentTitle",
            )}
          </h3>
          <p className="PermitIdComponentPopover-contentInfo">
            {t(
              "spages.listing.ListingDetailsPageBase.permitId.bodyPopover.contentInfo",
            )}
          </p>
          <p
            className="PermitIdComponentBodyPopover-permitIdContainer"
            data-testid="PermitIdComponentMultilistingContent"
          >
            <hr className="PermitIdComponentBodyPopover-seperator" />
            {listing.minBookingDurationPermitId}
            {listing.minBookingDurationImprintFileId && landlordImprintLink}
          </p>

          <Button
            onClick={() => setIsPopoverForBodyOpen(false)}
            colorVariant="secondary"
            widthVariant="fullWidth"
            dataTestId="PermitIdComponentGotItButton"
          >
            {t(
              "spages.listing.ListingDetailsPageBase.permitId.popover.gotItButton",
            )}
          </Button>
        </div>
      </Popover>
    );
  };

  return (
    <div data-testid="PermitIdComponentContainer">
      {renderTitle()}
      {renderBody()}
    </div>
  );
};

PermitIdComponent.propTypes = {
  listing: PropTypes.object.isRequired,
  smallScreen: PropTypes.bool.isRequired,
};

export default PermitIdComponent;
