import dayjs from "dayjs";
import Rollbar from "rollbar";
import getWFCONFIG from "../../../../utils/WF_CONFIG";

const WF_CONFIG = getWFCONFIG();

const EARLIEST_DATE = timestamp(dayjs.utc("0000-01-01"));
const LATEST_DATE = timestamp(dayjs.utc("4000-12-31"));

const rollbar = new Rollbar({
  accessToken: WF_CONFIG.ROLLBAR_POST_SERVER_ITEM_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
});

rollbar.configure({
  payload: { environment: WF_CONFIG.NODE_ENV },
});

export default arie;

function arie(config) {
  if (typeof config.minBookingDuration !== "number") {
    throw new Error("minBookingDuration must be a number");
  }

  // @TODO Fix it
  // When this error occurs, it takes the production down.
  // In order to stop crashing we redirect the user to 404 page for the time being.
  // We have a bug ticket for it, please see MQ-535.
  if (!Array.isArray(config.blockedDurations)) {
    const blockDurationError = new Error("blockedDurations must be an array");

    // Send the error to Rollbar
    rollbar.error(blockDurationError);

    blockDurationError.name = "ResourceNotFoundError";
    throw blockDurationError;
  }

  const availableFrom = config.availableFrom
    ? timestamp(config.availableFrom)
    : EARLIEST_DATE;

  const availableTo = config.availableTo
    ? timestamp(config.availableTo)
    : LATEST_DATE;

  const _blockedDurations = config.blockedDurations.concat();
  let _mergedBlockedDurations;

  return { blockedDurations, firstPossibleCheckin };

  function blockedDurations() {
    if (_mergedBlockedDurations) {
      return _mergedBlockedDurations;
    }

    const timestamped = durationToTimestamps(_blockedDurations);
    const sorted = timestamped
      .filter(
        (duration) =>
          duration.to >= availableFrom && duration.from <= availableTo,
      )
      .sort((d1, d2) => d1.from - d2.from);
    const merged = merge(sorted);
    const buffered = addBuffer(merged, config.minBookingDuration);
    const bufferedAroundAvailability = bufferAroundAvailability(
      availableFrom,
      availableTo,
      buffered,
      config.minBookingDuration,
    );

    _mergedBlockedDurations = durationToDateStrings(bufferedAroundAvailability);

    return _mergedBlockedDurations;
  }

  function firstPossibleCheckin() {
    const blocked = durationToTimestamps(blockedDurations());

    if (!blocked.length) {
      return config.availableFrom || dayjs.utc().format("YYYY-MM-DD");
    }

    for (const blockedDuration of blocked) {
      if (blockedDuration.from > availableFrom) {
        return config.availableFrom || dayjs.utc().format("YYYY-MM-DD");
      }

      if (blockedDuration.to < availableTo) {
        return dayjs.utc(blockedDuration.to).add(1, "day").format("YYYY-MM-DD");
      }
    }

    return "";
  }
}

function merge(durations) {
  const merged = [];

  while (durations.length) {
    const duration = durations.shift();
    const nextDuration = durations[0];

    // this is the last duration
    if (!nextDuration) {
      merged.push(duration);
      break;
    }

    // everything ok
    // duration [--------]
    // next            [--------]
    if (duration.to < nextDuration.from) {
      merged.push(duration);
      continue;
    }

    // duration [--------]
    // next       [--------]
    if (duration.to < nextDuration.to) {
      // [-------------]
      duration.to = nextDuration.to;
      merged.push(duration);
      // remove next duration
      durations.shift();
      continue;
    }

    // duration [---------------]
    // next       [--------]
    if (duration.to >= nextDuration.to) {
      // remove next duration
      durations.shift();
      // reevaluate duration next iteration
      durations.unshift(duration);
    }
  }

  return merged;
}

// this function will only work if the durations passed to it
// are sorted and merged
function addBuffer(mergedDurations, minBookingDuration) {
  const buffered = [];

  while (mergedDurations.length) {
    const duration = mergedDurations.shift();
    const nextDuration = mergedDurations[0];

    // convert minBookingDuration to duration with rounded unix timestamps
    const buffer = bufferAfter(duration.to, minBookingDuration);

    // this is the last duration
    if (!nextDuration) {
      buffered.push(duration);
      break;
    }

    // ok                 [----]
    // duration [--------]
    // next                     [--------]
    if (duration.to + buffer < nextDuration.from) {
      buffered.push(duration);
      continue;
    }

    // merge          [-------------]
    // merge          [----]
    // duration [--------]
    // next                [--------]
    duration.to = nextDuration.to;
    // remove next duration
    mergedDurations.shift();
    // reevaluate next iteration
    mergedDurations.unshift(duration);
  }

  return buffered;
}

// this function will only work if the durations passed to it
// are sorted, merged and buffered against each other
function bufferAroundAvailability(
  availableFrom,
  availableTo,
  bufferedDurations,
  minBookingDuration,
) {
  if (!bufferedDurations.length) {
    return bufferedDurations;
  }

  const bufferStart = bufferAfter(availableFrom, minBookingDuration);
  const bufferEnd = bufferAfter(availableTo, minBookingDuration);

  const buffered = [];

  while (bufferedDurations.length) {
    const duration = bufferedDurations.shift();

    // adjust   ----------]
    // buffer             [----]
    // duration              [----]
    if (duration.from - bufferStart < availableFrom) {
      duration.from = availableFrom;
    }

    // adjust            [------
    // buffer       [----]
    // duration [----]
    if (duration.to + bufferEnd > availableTo) {
      duration.to = availableTo;
    }

    buffered.push(duration);
  }

  return buffered;
}

function durationToTimestamps(duration) {
  if (Array.isArray(duration)) {
    return duration.map(durationToTimestamps);
  }

  return {
    from: timestamp(duration.from),
    to: timestamp(duration.to),
  };
}

function durationToDateStrings(duration) {
  if (Array.isArray(duration)) {
    return duration.map(durationToDateStrings);
  }

  return {
    from: dayjs.utc(duration.from).format("YYYY-MM-DD"),
    to: dayjs.utc(duration.to).format("YYYY-MM-DD"),
  };
}

function timestamp(date) {
  return Math.floor(dayjs.utc(date).valueOf() / 86400000) * 86400000;
}

function bufferAfter(date, minBookingDuration) {
  const buffer = durationToTimestamps({
    from: dayjs.utc(date).add(1, "day"),
    to: dayjs.utc(date).add(minBookingDuration, "months"),
  });

  return Math.max(0, buffer.to - buffer.from);
}
