import React from "react";
import dayjs from "dayjs";
import intervalPoint from "../DateMonthView/interval-point";

export default (from, to, opts = {}) => {
  const _from = from.props.value || dayjs.utc();
  const _to = to.props.value;

  const minDateTo =
    _from && opts.offset ? calculateMinDate(_from, opts.offset) : "";

  const maxDateFrom =
    _to && opts.offset
      ? dayjs
          .utc(_to)
          .subtract(opts.offset[0], opts.offset[1])
          .add(1, "day")
          .format("YYYY-MM-DD")
      : "";

  const linkedFrom = React.cloneElement(from, {
    setAttributes: intervalPoint(_to),
    maxDate: maxDateFrom || from.props.maxDate || "",
    onSelect,
  });

  const linkedTo = React.cloneElement(to, {
    minDate: minDateTo,
    setAttributes: intervalPoint(_from),
  });

  function onSelect(...args) {
    if (!from.props.onSelect) return;
    if (from.props.onSelect) {
      from.props.onSelect(...args);
    }
  }

  return { from: linkedFrom, to: linkedTo };
};

function calculateMinDate(from, offset) {
  const minDate = dayjs.utc(from).add(offset[0], offset[1]);

  // IF
  //   `offset` units is `month(s)`
  // THEN
  //   set `minDate` to end of previous month
  if (offset[1].startsWith("month")) {
    return minDate.subtract(1, "day").format("YYYY-MM-DD");
  }

  return minDate.format("YYYY-MM-DD");
}
