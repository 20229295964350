import React, { useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import copy from "copy-to-clipboard";
import SharingOption from "./SharingOption";
import { BADGE_STYLES, ICONS } from "./sharingUtils";

const CopyOption = ({
  defaultLabel,
  doneLabel,
  content,
  isDrawer,
  analyticsEvent,
}) => {
  const [isClicked, setIsClicked] = useState(false);

  const onClickHandler = () => {
    analyticsEvent("Share", "Copy");
    setIsClicked(true);
    copy(content);
    setTimeout(() => setIsClicked(false), 1000);
  };

  return (
    <SharingOption
      badgeStyle={BADGE_STYLES.copy}
      icon={ICONS.copy}
      label={isClicked ? doneLabel : defaultLabel}
      onClick={onClickHandler}
      className={classnames({ "SharingOption--inDrawer": isDrawer })}
      analyticsEvent={null}
    />
  );
};

CopyOption.propTypes = {
  defaultLabel: PropTypes.string.isRequired,
  doneLabel: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  isDrawer: PropTypes.bool,
  analyticsEvent: PropTypes.func,
};

export default CopyOption;
