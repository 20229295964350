import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const SmokingForbidden = ({ width = 30, height = 30 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 30 30"
      width={width}
      height={height}
    >
      <path
        fill="#f5f1fb"
        d="M15,0h0A15,15,0,0,1,30,15h0A15,15,0,0,1,15,30h0A15,15,0,0,1,0,15H0A15,15,0,0,1,15,0Z"
      />
      <path
        fill="#fae8e8"
        d="M15,1h0A14,14,0,0,1,29,15h0A14,14,0,0,1,15,29h0A14,14,0,0,1,1,15H1A14,14,0,0,1,15,1Z"
      />
      <rect
        fill="#e9ebf2"
        opacity="0"
        x="6"
        y="6"
        width={width}
        height={height}
      />
      <rect
        fill="#fff"
        x="7"
        y="13.59"
        width="16"
        height="4"
        rx="1"
        transform="matrix(0.97, 0.26, -0.26, 0.97, 4.55, -3.35)"
      />
      <path
        fill="#fae8e8"
        stroke="#000"
        strokeMiterlimit="10"
        strokeWidth="0.5px"
        d="M8.47,12.45h3.72a0,0,0,0,1,0,0V16a0,0,0,0,1,0,0H8.47a1,1,0,0,1-1-1V13.45A1,1,0,0,1,8.47,12.45Z"
        transform="translate(4.01 -2.06) rotate(15)"
      />
      <path
        fill="#eb5757"
        d="M21.49,15.57h1a0,0,0,0,1,0,0V19.1a0,0,0,0,1,0,0h-1a1,1,0,0,1-1-1V16.57A1,1,0,0,1,21.49,15.57Z"
        transform="translate(37.77 39.64) rotate(-165)"
      />
      <path d="M23.32,15.69a1.49,1.49,0,0,0-.91-.7L8.89,11.37a1.5,1.5,0,0,0-1.84,1.06l-.52,1.93a1.51,1.51,0,0,0,.15,1.14,1.49,1.49,0,0,0,.91.7l13.52,3.62a1.37,1.37,0,0,0,.4.05,1.4,1.4,0,0,0,.74-.2,1.46,1.46,0,0,0,.7-.91l.52-1.93A1.51,1.51,0,0,0,23.32,15.69Zm-15.25-.4a.74.74,0,0,1-.48-.89L8,12.93a.78.78,0,0,1,.35-.46.75.75,0,0,1,.38-.1h.1l3.44.92-.78,2.9Zm4.66-1.86,8,2.13-.78,2.9L12,16.33Zm7.65,5.16.78-2.9.78.21a.75.75,0,0,1,.48.89L22,18.26a.75.75,0,0,1-.86.54Z" />
      <path fill="#f5f1fb" d="M21.86,13.52c0-1.45-3.35-2.18-3.39-4.89" />
      <path d="M22.61,13.71l-.75-.19c0-.69-1.14-1.12-1.81-1.41a3.79,3.79,0,0,1-1.88-4.76l.6.27a4.29,4.29,0,0,0,2.32,3.7A2.72,2.72,0,0,1,22.61,13.71Z" />
      <path
        fill="#eb5757"
        d="M15,0A15,15,0,1,0,30,15,15,15,0,0,0,15,0ZM2,15A13,13,0,0,1,23.82,5.48L5.48,23.82A13,13,0,0,1,2,15ZM15,28a13,13,0,0,1-8.82-3.48L24.52,6.18A13,13,0,0,1,15,28Z"
      />
    </svg>
  );
};

SmokingForbidden.propTypes = propTypes;

export default SmokingForbidden;
