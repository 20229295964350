import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  t: PropTypes.func.isRequired,
  amenities: PropTypes.object.isRequired,
};

const general = [
  "washingMachineV2",
  "dryerV2",
  "coffee",
  "tv",
  "deskOrWorkspace",
  "wifi",
  "balcony",
  "terrace",
  "doorIntercom",
  "elevator",
];

const kitchen = [
  "dishWasher",
  "kettle",
  "stove",
  "fridge",
  "oven",
  "microwave",
  "toaster",
  "cutlery",
  "freezer",
];

const bedroom = [
  "bedClothes",
  "pillows",
  "blankets",
  "nightLamp",
  "closetOrDrawers",
];

const other = [
  "iron",
  "ironingBoard",
  "vacuumCleaner",
  "broom",
  "floorWiper",
  "bbq",
  "pool",
  "smokeDetector",
  "airConditioner",
  "inhouseCinema",
  "smartHomeTechnology",
  "underfloorHeating",
  "sauna",
  "wheelchair",
  "studyRooms",
  "communityLounge",
  "outdoorArea",
];

const bathroom = ["shower", "bathTub", "hairDryer", "bidet", "towels"];

const getSubtitle = (t, amenity) => {
  if (amenity === "AVAILABLE_IN_APARTMENT") {
    return t("listings.amenities.AVAILABLE_IN_APARTMENT");
  }

  if (amenity === "AVAILABLE_IN_BUILDING") {
    return t("listings.amenities.AVAILABLE_IN_BUILDING");
  }

  if (amenity === "AVAILABLE_NEAR_BUILDING") {
    return t("listings.amenities.AVAILABLE_NEAR_BUILDING");
  }
};

const AmenitiesListItems = ({ t, className, amenities, list }) => {
  return (
    <ul className={className}>
      {list.map((amenity) => {
        if (!amenities[amenity] || amenities[amenity] === "NOT_AVAILABLE") {
          return null;
        }
        const amenityName = amenity.substring(0, amenity.length - 2);
        return amenity.endsWith("V2") ? (
          <li key={amenity}>
            <span className={`amenity amenity--${amenityName}`}>
              <span>
                {t(`listings.amenities.${amenityName}`)}
                <span className="amenity-subtitle">
                  {getSubtitle(t, amenities[amenity])}
                </span>
              </span>
            </span>
          </li>
        ) : (
          <li key={amenity}>
            <span className={`amenity amenity--${amenity}`}>
              {t(`listings.amenities.${amenity}`)}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

AmenitiesListItems.propTypes = {
  t: PropTypes.func.isRequired,
  amenities: PropTypes.object.isRequired,
  className: PropTypes.string,
  list: PropTypes.array.isRequired,
};

const AmenitiesList = ({ t, amenities }) => (
  <div className="AmenitiesList">
    <div className="AmenitiesList-columns">
      <div className="AmenitiesList-category">
        <h4 className="AmenitiesList-categoryTitle">
          {t("listings.amenities.general")}
        </h4>
        <AmenitiesListItems t={t} amenities={amenities} list={general} />
      </div>
      <div className="AmenitiesList-category">
        <h4 className="AmenitiesList-categoryTitle">
          {t("listings.amenities.kitchen")}
        </h4>
        <AmenitiesListItems t={t} amenities={amenities} list={kitchen} />
      </div>
      <div className="AmenitiesList-category">
        <h4 className="AmenitiesList-categoryTitle">
          {t("listings.amenities.bedroom")}
        </h4>
        <AmenitiesListItems t={t} amenities={amenities} list={bedroom} />
      </div>
      <div className="AmenitiesList-category">
        <h4 className="AmenitiesList-categoryTitle">
          {t("listings.amenities.bathroom")}
        </h4>
        <AmenitiesListItems t={t} amenities={amenities} list={bathroom} />
      </div>
    </div>

    <div className="AmenitiesList-category" style={{ marginTop: 15 }}>
      <h4 className="AmenitiesList-categoryTitle">
        {t("listings.amenities.other")}
      </h4>
      <AmenitiesListItems
        t={t}
        className="AmenitiesList-columns"
        amenities={amenities}
        list={other}
      />
    </div>
  </div>
);

AmenitiesList.propTypes = propTypes;
export default AmenitiesList;
