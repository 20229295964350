import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import dayjs from "dayjs";
import d from "../../../../../utils/dates";

const EARLIEST_DATE = new Date(-8640000000000000 / 2);
const LATEST_DATE = new Date(8640000000000000 / 2);

class MonthlyAvailabilityViewer extends React.Component {
  static propTypes = {
    defaultMonth: PropTypes.string,
    blocks: PropTypes.array,
    availableFrom: PropTypes.string,
    availableTo: PropTypes.string,
    maxOffset: PropTypes.number,
    minOffset: PropTypes.number,
  };

  static defaultProps = {
    defaultMonth: dayjs().format("YYYY-MM-DD"),
    minOffset: 0,
    maxOffset: 2,
    blocks: [],
    availableFrom: null,
    availableTo: null,
  };

  constructor(props) {
    super();
    this.onClickPrevious = this.onClickPrevious.bind(this);
    this.onClickNext = this.onClickNext.bind(this);
    this.state = {
      occupancy: this.occupancy(props),
      offset: 0,
      componentMounted: false,
    };

    this.backButtonRef = React.createRef();
    this.nextButtonRef = React.createRef();
  }

  // TODO: https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html
  /* eslint-disable-next-line camelcase */
  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ occupancy: this.occupancy(nextProps) });
  }

  componentDidMount() {
    this.setState({ componentMounted: true });
  }

  onClickPrevious() {
    const { offset } = this.state;
    const { minOffset } = this.props;
    if (offset === minOffset) return;
    this.setState({ offset: offset - 1 });
  }

  onClickNext() {
    const { offset } = this.state;
    const { maxOffset } = this.props;
    if (offset === maxOffset) return;
    this.setState({ offset: offset + 1 });
  }

  invert(range) {
    const inverted = [];

    if (range.from) {
      const to = dayjs(d.normalizeFrom(range.from)).subtract(1, "day");
      inverted.push({ from: EARLIEST_DATE, to });
    }

    if (range.to) {
      const from = dayjs(d.normalizeTo(range.to)).add(1, "day");
      inverted.push({ from, to: LATEST_DATE });
    }

    return inverted.map(d.normalizeBlock);
  }

  occupancy = (props) => {
    const {
      minOffset,
      maxOffset,
      blocks,
      availableFrom,
      availableTo,
      defaultMonth,
    } = props;
    const availability = { from: availableFrom, to: availableTo };

    return d.occupancyPerMonth(
      {
        from: dayjs(defaultMonth)
          .add(minOffset - 1, "years")
          .format("YYYY-MM-DD"),
        to: dayjs(defaultMonth)
          .add(maxOffset + 1, "years")
          .format("YYYY-MM-DD"),
      },
      blocks.concat(this.invert(availability)),
    );
  };

  displayFrom() {
    return dayjs(this.props.defaultMonth)
      .add(this.state.offset, "years")
      .startOf("month");
  }

  displayTo() {
    return dayjs(this.props.defaultMonth)
      .add(this.state.offset, "years")
      .add(11, "months");
  }

  getMonthsView({ displayFrom, displayTo }) {
    return d
      .rangeToMonths({
        from: displayFrom.format("YYYY-MM-DD"),
        to: displayTo.format("YYYY-MM-DD"),
      })
      .map((month) => {
        const key = month.format("YYYY-MM");
        const occupancy = this.state.occupancy[key];

        let status = "partlyAvailable";
        if (occupancy === 0) status = "available";
        if (occupancy === 1) status = "unavailable";

        // When Server's and Client's timezones are different, the availability
        // is calculated incorrectly during SSR. So we don't show status during SSR
        // and we re-calculate it once the component is mounted. (componentDidMount is
        // only called on the client so we use that to determine where the render is happening).
        // Original issue: https://wunderflats.atlassian.net/browse/WU-1497
        // Followup issue: https://wunderflats.atlassian.net/browse/DEV-11089
        const monthViewClassName = this.state.componentMounted
          ? classnames(
              "MonthlyAvailabilityViewer-month",
              `MonthlyAvailabilityViewer-month--${status}`,
            )
          : "MonthlyAvailabilityViewer-month";

        const monthView = (
          <li key={key} className={monthViewClassName}>
            <span>{month.format("MMM YYYY")}</span>
          </li>
        );

        return monthView;
      });
  }

  render() {
    const { offset } = this.state;
    const { minOffset, maxOffset } = this.props;
    const displayFrom = this.displayFrom();
    const displayTo = this.displayTo();

    const months = this.getMonthsView({ displayFrom, displayTo });

    const current = displayFrom.isSame(displayTo, "year")
      ? displayFrom.format("YYYY")
      : `${displayFrom.format("YYYY")}/${displayTo.format("YYYY")}`;

    return (
      <div className="MonthlyAvailabilityViewer">
        <ol className="MonthlyAvailabilityViewer-monthsContainer">{months}</ol>
        <div className="MonthlyAvailabilityViewer-yearSelect">
          <button
            ref={this.backButtonRef}
            className="MonthlyAvailabilityViewer-selectPreviousYear"
            disabled={offset === minOffset}
            onClick={this.onClickPrevious}
          />
          <span className="MonthlyAvailabilityViewer-currentYear">
            {current}
          </span>
          <button
            ref={this.nextButtonRef}
            className="MonthlyAvailabilityViewer-selectNextYear"
            disabled={offset === maxOffset}
            onClick={this.onClickNext}
          />
        </div>
      </div>
    );
  }
}

export default MonthlyAvailabilityViewer;
