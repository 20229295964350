import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";

import "./SharingOption.scss";

const propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.node,
  href: PropTypes.string,
  badgeStyle: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  analyticsEvent: PropTypes.func,
};

const SharingOption = ({
  label,
  icon,
  href,
  badgeStyle = "default",
  className,
  analyticsEvent,
  onClick,
  ...rest
}) => {
  const El = href ? "a" : "button";
  return (
    <div
      className={classnames("SharingOption", className)}
      data-testid="SharingOption"
    >
      <span className="SharingOption-badgeContainer">
        <El
          href={href}
          data-testid={`SharingOption-link--${label.replaceAll(" ", "-")}`}
          className={`SharingOption-badge--${badgeStyle} SharingOption-link`}
          target="_blank"
          onClick={() => {
            if (analyticsEvent) {
              analyticsEvent("Share", label);
            }
            if (onClick) {
              onClick();
            }
          }}
          {...rest}
        >
          {icon}
        </El>
      </span>
      <span className="SharingOption-label">{label}</span>
    </div>
  );
};

SharingOption.propTypes = propTypes;
export default SharingOption;
