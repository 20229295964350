import React from "react";
import PropTypes from "prop-types";

import "./FormHelpText.scss";

const propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
};

const FormHelpText = ({ className, children, dataTestId }) => (
  <div
    data-testid={dataTestId}
    className={`FormHelpText${className ? ` ${className}` : ""}`}
  >
    {children}
  </div>
);

FormHelpText.propTypes = propTypes;
export default FormHelpText;
