import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  t: PropTypes.func.isRequired,
  numApartments: PropTypes.number,
  label: PropTypes.string,
  secondaryText: PropTypes.string,
  fullWidth: PropTypes.bool,
  angular: PropTypes.bool,
};

function MultilistingBadge({
  t,
  numApartments,
  label,
  secondaryText,
  fullWidth,
  angular,
}) {
  if (label == null && numApartments == null) {
    throw new Error("Please provide prop label or numApartments");
  }

  return (
    <span
      className={classNames("MultilistingBadge", {
        "MultilistingBadge--fullWidth": !!fullWidth,
        "MultilistingBadge--angular": !!angular,
      })}
    >
      <span className="MultilistingBadge-label">
        {label || t("numApartments", { numApartments })}
      </span>
      {secondaryText && (
        <span className="MultilistingBadge-secondaryText">{secondaryText}</span>
      )}
    </span>
  );
}

MultilistingBadge.propTypes = propTypes;

export default MultilistingBadge;
