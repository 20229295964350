import React from "react";

const Instagram = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="url(#paint0_linear)"
      fillRule="evenodd"
      d="M15.75 2h-7.5A6.25 6.25 0 002 8.25v7.5A6.25 6.25 0 008.25 22h7.5A6.25 6.25 0 0022 15.75v-7.5A6.25 6.25 0 0015.75 2zm4.375 13.75a4.38 4.38 0 01-4.375 4.375h-7.5a4.38 4.38 0 01-4.375-4.375v-7.5A4.38 4.38 0 018.25 3.875h7.5a4.38 4.38 0 014.375 4.375v7.5zM12 7a5 5 0 100 10 5 5 0 000-10zm0 8.125A3.13 3.13 0 018.875 12 3.129 3.129 0 0112 8.875 3.129 3.129 0 0115.125 12 3.13 3.13 0 0112 15.125zm6.041-8.5a.666.666 0 11-1.332 0 .666.666 0 011.332 0z"
      clipRule="evenodd"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="16.441"
        x2="6.193"
        y1="2"
        y2="21.565"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#405DE6" />
        <stop offset="0.13" stopColor="#5851DB" />
        <stop offset="0.245" stopColor="#833AB4" />
        <stop offset="0.333" stopColor="#C13584" />
        <stop offset="0.432" stopColor="#E1306C" />
        <stop offset="0.516" stopColor="#FD1D1D" />
        <stop offset="0.646" stopColor="#F56040" />
        <stop offset="0.792" stopColor="#F77737" />
        <stop offset="0.917" stopColor="#FCAF45" />
        <stop offset="1" stopColor="#FFDC80" />
      </linearGradient>
    </defs>
  </svg>
);

export default Instagram;
