import React, { useState } from "react";
import PropTypes from "prop-types";
import useMedia from "../../hooks/useMedia";
import { useI18n } from "../../spages/spa/context/I18nContext";
import { useRouterUtils } from "../../spages/spa/context/RouterUtilsContext";
import { Button } from "../DesignSystem/Button/Button";
import Popover, { placements } from "../Popover/Popover";
import "./TenantServiceFeeInfoPopOver.scss";

const TenantServiceFeeInfoPopOver = ({
  title,
  placement = placements.bottom,
}) => {
  const { t } = useI18n();
  const isSmallScreen = useMedia("(max-width: 768px)");
  const { url } = useRouterUtils();

  const [
    isTenantServiceFeeInfoTextVisible,
    setIsTenantServiceFeeInfoTextVisible,
  ] = useState(false);

  return (
    <Popover
      showInDrawer={isSmallScreen}
      show={isTenantServiceFeeInfoTextVisible}
      onClose={() => setIsTenantServiceFeeInfoTextVisible(false)}
      placement={placement}
      preventScroll
      around={
        <button
          type="button"
          className="TenantServiceFeeInfoPopOver-toggleButton"
          data-testid="TenantServiceFeeInfoPopOver-toggleButton"
          onClick={() =>
            setIsTenantServiceFeeInfoTextVisible((prevState) => !prevState)
          }
        >
          {title}
        </button>
      }
    >
      <section
        className="TenantServiceFeeInfoPopOver-content"
        data-testid="TenantServiceFeeInfoPopOver-content"
      >
        <p className="TenantServiceFeeInfoPopOver-contentInfo">
          {t("components.TenantServiceFeeInfoPopOver.shortInfoOnServiceFee")}
          <span
            className="TenantServiceFeeInfoPopOver-linkContainer"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t(
                "components.TenantServiceFeeInfoPopOver.moreDetailsOnServiceFee",
                {
                  faqLink: `<a target="_blank" href=${url("helpSinglePost", {
                    postId: "75000054485",
                  })}>${t("faqPage")}</a>`,
                },
              ),
            }}
          />
        </p>
        {isSmallScreen && (
          <div
            className="TenantServiceFeeInfoPopOver-gotItButtonContainer"
            data-testid="TenantServiceFeeInfoPopOver-gotItButtonContainer"
          >
            <Button
              onClick={() => setIsTenantServiceFeeInfoTextVisible(false)}
              colorVariant="secondary"
              widthVariant="fullWidth"
            >
              {t("gotIt")}
            </Button>
          </div>
        )}
      </section>
    </Popover>
  );
};

TenantServiceFeeInfoPopOver.propTypes = {
  title: PropTypes.node,
  placement: PropTypes.oneOf(Object.values(placements)),
};

export default TenantServiceFeeInfoPopOver;
