import React from "react";

const WhatsApp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#75C96B"
      d="M6.54 20.521l.377.189a9.817 9.817 0 005.073 1.38C17.485 22.076 22 17.55 22 12.055 22 9.4 20.945 6.85 19.07 4.97A9.975 9.975 0 0011.97 2C6.5 2 2 6.501 2 11.97v.13a10.29 10.29 0 001.506 5.267l.25.377-1.003 3.7 3.788-.923z"
    />
    <path
      fill="#fff"
      d="M17.615 14.564l-.69-.313s-1.003-.44-1.63-.753c-.063 0-.126-.063-.188-.063a.91.91 0 00-.44.126c-.133.078-.062.063-.94 1.066a.345.345 0 01-.314.188h-.062a.478.478 0 01-.251-.125l-.314-.126a6.09 6.09 0 01-1.818-1.191c-.126-.126-.314-.251-.44-.377a7.016 7.016 0 01-1.19-1.505l-.063-.125a.915.915 0 01-.126-.25.47.47 0 01.063-.314c.057-.094.25-.314.439-.502.188-.188.188-.313.313-.439a.772.772 0 00.126-.627 24.496 24.496 0 00-1.003-2.383.729.729 0 00-.44-.313h-.69c-.125 0-.25.062-.375.062l-.063.063c-.126.063-.251.188-.376.25-.126.064-.189.252-.314.377a3.197 3.197 0 00-.69 1.944c.007.497.114.987.314 1.442l.063.188a10.452 10.452 0 002.32 3.198l.25.251c.183.151.351.32.502.502a11.196 11.196 0 004.509 2.383c.188.063.439.063.627.125h.627a2.32 2.32 0 00.94-.25c.154-.07.301-.154.44-.251l.125-.126c.125-.125.25-.188.376-.313a1.66 1.66 0 00.314-.377c.12-.28.205-.575.25-.877v-.44a.79.79 0 00-.188-.125"
    />
  </svg>
);

export default WhatsApp;
