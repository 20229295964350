import React, { useState } from "react";
import PropTypes from "prop-types";
import { misc as miscConstants } from "@wunderflats/constants";
import { Button } from "../../../../components/DesignSystem/Button/Button";
import { IconButton } from "../../../../components/DesignSystem/IconButton/IconButton";
import CloseIcon from "../../../../components/Icons/Close";
import InfoIcon from "../../../../components/Icons/InfoIcon";
import Popover, { placements } from "../../../../components/Popover/Popover";
import { useI18n } from "../../context/I18nContext";

const {
  labels: { REGISTRATION_NUMBER: REGISTRATION_NUMBER_LABEL },
} = miscConstants;

const RegistrationNumber = ({ listing, smallScreen = false }) => {
  const { t } = useI18n();
  const [isPopoverForTitleOpen, setIsPopoverForTitleOpen] = useState(false);
  const [isPopoverForBodyOpen, setIsPopoverForBodyOpen] = useState(false);

  if (!listing.labels.includes(REGISTRATION_NUMBER_LABEL)) {
    return null;
  }

  const renderTitle = () => {
    return (
      <Popover
        showInDrawer={smallScreen}
        show={isPopoverForTitleOpen}
        onClose={() => setIsPopoverForTitleOpen(false)}
        placement={placements.right}
        preventScroll
        around={
          <button
            type="button"
            className="RegistrationNumberTitlePopover-toggleButton"
            onClick={() => setIsPopoverForTitleOpen(!isPopoverForTitleOpen)}
          >
            <h4>
              {t(
                "spages.listing.ListingDetailsPageBase.registrationNumber.title",
              )}
            </h4>
            <InfoIcon />
          </button>
        }
      >
        {!smallScreen && (
          <IconButton
            size="small"
            className="RegistrationNumberPopover-closeButton"
            onClick={() => setIsPopoverForTitleOpen(false)}
            icon={<CloseIcon width={18} height={18} />}
          />
        )}

        <div className="RegistrationNumberPopover-content">
          <h3 className="RegistrationNumberPopover-contentTitle">
            {t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.titlePopover.contentTitle",
            )}
          </h3>
          <p className="RegistrationNumberPopover-contentInfo">
            {t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.titlePopover.contentInfo",
            )}
          </p>
          <Button
            onClick={() => setIsPopoverForTitleOpen(false)}
            colorVariant="secondary"
            widthVariant="fullWidth"
          >
            {t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.popover.gotItButton",
            )}
          </Button>
        </div>
      </Popover>
    );
  };

  const renderBody = () => {
    if (!listing.partOfGroup) {
      return (
        <span
          data-testid="RegistrationNumberForSingleListing"
          data-hasRegistrationNumber={Boolean(listing.registrationNumber)}
        >
          {listing.registrationNumber ||
            t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.notAvailable",
            )}
        </span>
      );
    }

    const subListingsWithRegistrationNumber = listing.group.filter(
      (group) => group.registrationNumber,
    );

    const doesSomeSubListingContainRegistrationNumber =
      subListingsWithRegistrationNumber.length > 0;

    return (
      <Popover
        showInDrawer={smallScreen}
        show={isPopoverForBodyOpen}
        onClose={() => setIsPopoverForBodyOpen(false)}
        placement={placements.right}
        preventScroll
        around={
          <button
            type="button"
            data-testid="RegistrationNumberToggleForMultiListing"
            className="RegistrationNumberBodyPopover-toggleButton"
            onClick={() => setIsPopoverForBodyOpen(!isPopoverForBodyOpen)}
          >
            {t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.bodyPopover.toggleButton",
            )}
          </button>
        }
      >
        {!smallScreen && (
          <IconButton
            size="small"
            className="RegistrationNumberPopover-closeButton"
            onClick={() => setIsPopoverForBodyOpen(false)}
            icon={<CloseIcon width={18} height={18} />}
          />
        )}

        <div className="RegistrationNumberPopover-content">
          <h3 className="RegistrationNumberPopover-contentTitle">
            {t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.bodyPopover.contentTitle",
            )}
          </h3>
          <p className="RegistrationNumberPopover-contentInfo">
            {t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.bodyPopover.contentInfo",
            )}
          </p>
          <p className="RegistrationNumberBodyPopover-registrationNumbersContainer">
            <hr className="RegistrationNumberBodyPopover-seperator" />
            {doesSomeSubListingContainRegistrationNumber ? (
              <ul>
                {subListingsWithRegistrationNumber.map((group) => (
                  <li
                    key={group.registrationNumber}
                    data-testid="RegistrationNumberForMultiListing"
                    className="RegistrationNumberBodyPopover-registrationNumber"
                  >
                    {group.registrationNumber}
                  </li>
                ))}
              </ul>
            ) : (
              <p
                className="RegistrationNumberBodyPopover-registrationNumber"
                data-testid="RegistrationNumberForMultiListingIsEmpty"
              >
                {t(
                  "spages.listing.ListingDetailsPageBase.registrationNumber.notAvailable",
                )}
              </p>
            )}
          </p>
          <Button
            onClick={() => setIsPopoverForBodyOpen(false)}
            colorVariant="secondary"
            widthVariant="fullWidth"
            dataTestId="RegistrationNumberGotItButton"
          >
            {t(
              "spages.listing.ListingDetailsPageBase.registrationNumber.popover.gotItButton",
            )}
          </Button>
        </div>
      </Popover>
    );
  };

  return (
    <div data-testid="RegistrationNumberContainer">
      {renderTitle()}
      {renderBody()}
    </div>
  );
};

RegistrationNumber.propTypes = {
  listing: PropTypes.object.isRequired,
  smallScreen: PropTypes.bool.isRequired,
};

export default RegistrationNumber;
