import React from "react";

function Share(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      {...props}
    >
      <path
        fill="#24272E"
        d="M17 8.291L10.35 1v4.35H8.938A7.937 7.937 0 001 13.287v2.305l.628-.688a11.268 11.268 0 018.323-3.672h.4v4.35L17 8.293z"
      />
    </svg>
  );
}

export default Share;
