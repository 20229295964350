import React from "react";
import PropTypes from "prop-types";

const propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

const Gallery = ({ width = 18, height = 18 }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 18"
    >
      <rect fill="#e9ebf2" opacity="0" width={width} height={height} />
      <rect fill="#fff" x="1.5" y="3" width="15" height="12" rx="2" />
      <path
        fill="#24272e"
        d="M14.5,2H3.5a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3h11a3,3,0,0,0,3-3V5A3,3,0,0,0,14.5,2ZM3.5,4h11a1,1,0,0,1,1,1V9.5l-5,2.5L6.74,9.87a2.8,2.8,0,0,0-3,.12l-1.27.86V5A1,1,0,0,1,3.5,4Z"
      />
      <circle fill="#24272e" cx="10.49" cy="6.56" r="1.88" />
    </svg>
  );
};

Gallery.propTypes = propTypes;

export default Gallery;
