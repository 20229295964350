/* eslint-disable local/only-existing-locales */
import React from "react";
import PropTypes from "prop-types";
import { listings } from "@wunderflats/constants";

const { ROOM_TYPES } = listings;
const BEDROOM = ROOM_TYPES.Bedroom;
const LIVINGROOM = ROOM_TYPES.LivingDiningRoom;
const OTHER = ROOM_TYPES.Other;

const getRoomTitleTranslation = ({ t, key, count }) => {
  switch (key) {
    case BEDROOM:
      return t("bedroom", { num: count });
    case LIVINGROOM:
      return t("livingRoom", { num: count });
    case OTHER:
      return t("other", { num: count });
    default:
      return null;
  }
};

const getBedCountTranslation = ({ t, key, count }) => {
  switch (key) {
    case "singleBedCount":
      return t("singleBeds", { beds: count });
    case "doubleBedCount":
      return t("doubleBeds", { beds: count });
    case "guestBedCount":
      return t("bunkBeds", { beds: count });
    case "couchBedCount":
      return t("sofaBeds", { beds: count });
    case "othersCount":
      return t("otherBeds", { beds: count });
    default:
      return null;
  }
};

const sortByRoomType = (a, b) =>
  a.roomType ? a.roomType.localeCompare(b.roomType) : 0;
const getInitialRoomCountByType = (roomsInformation) => {
  const count = {
    [BEDROOM]: 0,
    [LIVINGROOM]: 0,
    [OTHER]: 0,
  };

  roomsInformation.map((info) => count[info.roomType]++);

  return {
    [BEDROOM]: count[BEDROOM] > 1 ? 0 : null,
    [LIVINGROOM]: count[LIVINGROOM] > 1 ? 0 : null,
    [OTHER]: count[OTHER] > 1 ? 0 : null,
  };
};

const BedsList = ({ t, roomsInformation = [] }) => {
  const roomCount = getInitialRoomCountByType(roomsInformation);

  return (
    <div className="BedsList">
      {[...roomsInformation].sort(sortByRoomType).map((roomInfo) => {
        const totalBeds = Object.values(roomInfo)
          .filter((bedType) => typeof bedType === "number")
          .reduce((acc, curr) => (acc += curr), 0);

        if (totalBeds === 0) {
          return null;
        }

        return Object.keys(roomInfo).map((bedType) =>
          roomInfo[bedType] && typeof roomInfo[bedType] === "number" ? (
            <div
              key={bedType}
              className={`BedsList-category ${bedType}`}
              data-testid={`BedsList-category-${roomInfo.roomType}`}
            >
              <h4 className="BedsList-categoryTitle">
                {getBedCountTranslation({
                  t,
                  key: bedType,
                  count: roomInfo[bedType],
                })}
              </h4>
              <p className="BedsList-categorySubtitle">
                {getRoomTitleTranslation({
                  t,
                  key: roomInfo.roomType,
                  count: roomCount[roomInfo.roomType],
                })}
              </p>
            </div>
          ) : null,
        );
      })}
    </div>
  );
};

BedsList.propTypes = {
  t: PropTypes.func.isRequired,
  roomsInformation: PropTypes.array.isRequired,
};
export default BedsList;
